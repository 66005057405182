var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "disk-header-container" },
    [
      _c(
        "div",
        { staticClass: "shadow-box" },
        [
          _vm.isHugeFileMode && !_vm.isFolder
            ? _c("el-progress", { attrs: { percentage: _vm.uploadPercentage } })
            : _vm._e(),
        ],
        1
      ),
      _c("div", { staticClass: "title-and-add" }, [
        _vm._m(0),
        _vm.breadcrumbList.length > 1
          ? _c("div", { staticClass: "btn-area" }, [
              _c("div", { staticClass: "btn-commom add-btn" }, [
                _c(
                  "span",
                  {
                    staticClass: "btn-flex",
                    on: { click: _vm.handleAddNewInit },
                  },
                  [
                    _c("i", { staticClass: "el-icon-plus" }),
                    _c("span", [_vm._v("ADD")]),
                  ]
                ),
              ]),
              _c(
                "div",
                { staticClass: "btn-commom upload-btn" },
                [
                  _c(
                    "el-popover",
                    {
                      ref: "uploadDom",
                      attrs: {
                        placement: "bottom",
                        trigger: "click",
                        "popper-class": "operationPopover",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading.fullscreen.lock",
                              value: _vm.uploadLoading,
                              expression: "uploadLoading",
                              modifiers: { fullscreen: true, lock: true },
                            },
                          ],
                          staticClass: "popover-content",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "operation-item" },
                            [
                              _c("i", { staticClass: "el-icon-document" }),
                              _c(
                                "el-upload",
                                {
                                  attrs: {
                                    action: "",
                                    multiple: "",
                                    "http-request": _vm.handleUploadFile,
                                    "show-file-list": false,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "el-upload__text" },
                                    [_vm._v("Upload File")]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "upload-item" }, [
                            _c("input", {
                              ref: "folderUploadInput",
                              staticClass: "originInput",
                              attrs: { type: "file", webkitdirectory: "" },
                              on: { change: _vm.handleUploadFloder },
                            }),
                            _c("div", { staticClass: "add-from-source" }, [
                              _c("i", { staticClass: "el-icon-folder" }),
                              _c("span", { staticClass: "add-title" }, [
                                _vm._v("Upload Folder"),
                              ]),
                            ]),
                          ]),
                        ]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "btn-flex",
                          attrs: { slot: "reference" },
                          slot: "reference",
                        },
                        [
                          _c("i", { staticClass: "el-icon-upload" }),
                          _c("span", [_vm._v("UPLOAD")]),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ])
          : _c("div", { staticClass: "btn-area only-add" }, [
              _c("div", { staticClass: "btn-commom only-add-btn" }, [
                _c(
                  "span",
                  {
                    staticClass: "btn-flex",
                    on: { click: _vm.handleAddNewInit },
                  },
                  [
                    _c("i", { staticClass: "el-icon-plus" }),
                    _c("span", [_vm._v("ADD")]),
                  ]
                ),
              ]),
            ]),
      ]),
      _c(
        "div",
        { staticClass: "breadcrumb-area" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            _vm._l(_vm.breadcrumbList, function (item, index) {
              return _c(
                "el-breadcrumb-item",
                {
                  key: index + "breadcrumb",
                  class: { lastItem: index === _vm.breadcrumbList.length - 1 },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.handleBreadcrumbJump(item, index)
                    },
                  },
                },
                [_vm._v(_vm._s(item.label))]
              )
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Add New Folder",
            visible: _vm.addNewDialogVisible,
            "custom-class": "addNewFolderDialog",
          },
          on: {
            "update:visible": function ($event) {
              _vm.addNewDialogVisible = $event
            },
            close: _vm.handleAddNewClosed,
          },
        },
        [
          _c(
            "el-dialog",
            {
              attrs: {
                title: "Permission Management",
                visible: _vm.authDialogVisible,
                "append-to-body": "",
                width: "24%",
                "custom-class": "authSettingDialog",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.authDialogVisible = $event
                },
                close: _vm.handleAuthClosed,
              },
            },
            [
              _c(
                "div",
                { staticClass: "dialog-content" },
                [
                  _c("AuthPermission", {
                    attrs: { storageFileId: _vm.addFolderId },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            { staticClass: "dialog-content" },
            [
              _c("el-input", {
                attrs: { placeholder: "type folder name" },
                model: {
                  value: _vm.folderInputName,
                  callback: function ($$v) {
                    _vm.folderInputName = $$v
                  },
                  expression: "folderInputName",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.addNewDialogVisible = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleCreatNewFolder },
                },
                [_vm._v("Save")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "disk-titile" }, [
      _c("span", [_vm._v("TEAM FILES")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }