<template>
  <div
    class="cloud-disk-box-container"
    v-loading="tableLoading || downloadLoading"
    :element-loading-text="loadingText"
  >
    <div class="cloud-disk-header">
      <DiskHeader />
    </div>
    <div class="cloud-disk-content">
      <DiskContent
        @downloadLoading="downloadLoading = $event"
        @tableLoading="tableLoading = $event"
      />
    </div>
  </div>
</template>

<script>
import DiskHeader from './DiskHeader'
import DiskContent from './DiskContent'
export default {
  components: {
    DiskHeader,
    DiskContent
  },
  computed: {
    loadingText() {
      // 只有当 downloadLoading 为 true 时才显示文字
      return this.downloadLoading ? '正在获取资源下载路径' : ''
    }
  },
  data() {
    return {
      tableLoading: false,
      downloadLoading: false
    }
  }
}
</script>
<style lang="scss">
.cloud-disk-box-container {
  .el-loading-mask {
    z-index: 999 !important;
  }
}
</style>
<style lang="scss" scoped>
.cloud-disk-box-container {
  width: calc(100% - 80px);
  height: calc(100% - 80px);
  padding: 20px 40px 30px 40px;

  .cloud-disk-header {
    height: 80px;
  }
  .cloud-disk-content {
    padding: 0 16px;
    width: calc(100% - 32px);
    height: calc(100% - 80px);
    border-radius: 6px;
    background: #fff;
    // box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  }
  @media (max-width: 768px) {
    .cloud-disk-content {
      padding: 0;
      width: 100%;
      background: transparent;
    }
  }
}
</style>
