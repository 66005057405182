<template>
  <div class="auth-permission-container">
    <div class="permission-title">
      <i class="el-icon-user-solid"></i> 指定人可访问
    </div>
    <div class="add-member-btn">
      <i class="el-icon-user"></i>
      <span @click="handleAddMemberInit">添加指定人</span>
    </div>
    <div class="members-data-list" v-loading="authLoading">
      <p class="total-person">
        组织架构 {{ orgTotal }} | 角色 {{ roleTotal }} | 员工 {{ userTotal }} 人
      </p>
      <div class="member-list-content">
        <div
          class="member-list-item"
          v-for="(item, index) in memebersInfoList"
          :key="index + 'member'"
        >
          <div class="person-info">
            <img :src="item.avatar" alt="" />
            <span>{{ item.label }}</span>
          </div>
          <div class="permissson-setting">
            <!-- :entityType="item.entityType"
              :entityId="item.value" -->
            <PermissionPopover
              :authType="item.purviewType"
              @changeThePermissionLabel="handleSettingPermisson($event, item)"
              @deleteAuthMemberEvent="handleDeleteAuthMember(item)"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- 添加指定人的dialog -->
    <el-dialog
      width="45%"
      title="请选择"
      :visible.sync="searchMemberDialogVisible"
      append-to-body
      custom-class="searchMembersDialog"
      @close="handleClosedselectDialog"
    >
      <div class="dialog-content">
        <div class="search-by-name">
          <el-select
            v-model="selectAuthUserList"
            multiple
            filterable
            remote
            default-first-option
            placeholder="search"
            :remote-method="remoteSearchMethod"
            :loading="searchLoading"
            popper-class="serachByKeywordDropdown"
            @change="handleSelectUser"
          >
            <el-option
              v-for="item in searchMemberList"
              :key="item.value + item.label"
              :label="item.label"
              :value="item"
            >
              <img :src="item.avatar" alt="" />
              <span>{{ item.label }}</span>
            </el-option>
          </el-select>
        </div>
        <div class="search-by-other">
          <SelectByEntity v-if="searchMemberDialogVisible" />
        </div>
      </div>
      <div class="select-default-auth">
        <el-radio-group v-model="defaultAuthType">
          <el-radio :label="1">仅可查看</el-radio>
          <el-radio :label="2">可查看/下载</el-radio>
          <el-radio :label="3">可编辑</el-radio>
          <el-radio :label="4">可管理</el-radio>
        </el-radio-group>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button @click="handleClosedselectDialog">Cancel</el-button>
        <el-button type="primary" @click="handleJumpToAuthSetting"
          >Save</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import PermissionPopover from '../components/PermissionPopover'
import SelectByEntity from './SelectByEntity'

import { Message, MessageBox } from 'element-ui'
import {
  getUserPermissionList,
  searchUserByValue,
  updatePermissionUserList,
  updataFileAndFolderAuth,
  deleteUserPermission
} from '../api/index'

export default {
  components: {
    PermissionPopover,
    SelectByEntity
  },
  props: {
    storageFileId: {
      type: Number,
      require: true
    },
    isYunLinkPage: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      authLoading: false,
      memebersInfoList: [],
      searchMemberDialogVisible: false,
      selectAuthUserList: [],
      searchLoading: false,

      searchMemberList: [],
      selectSchoolId: null,
      schoolDataList: [],
      currentRoleDataList: [],
      props: {
        label: 'name',
        children: 'memberList'
      },
      searchTypeId: 1,
      filterUserData: [],
      defaultAuthType: 1
    }
  },
  computed: {
    orgTotal() {
      return this.memebersInfoList.filter((item) => item.entityType === 2)
        .length
    },
    roleTotal() {
      return this.memebersInfoList.filter((item) => item.entityType === 3)
        .length
    },
    userTotal() {
      return this.memebersInfoList.filter((item) => item.entityType === 1)
        .length
    }
  },
  watch: {
    '$store.state.selectUserDataList': {
      handler(value) {
        // value, this.selectAuthUserList,
        // console.log(this.searchMemberList)
        this.selectAuthUserList = []
        this.searchMemberList = []
        this.selectAuthUserList = [...this.filterUserData, ...value]
        this.searchMemberList.push(...[...this.filterUserData, ...value])
      },
      deep: true
    }
  },

  mounted() {
    this.getAuthUserDataList()
  },
  methods: {
    /**
     * @description: user权限列表的获取
     * @return {*}
     */
    async getAuthUserDataList() {
      this.authLoading = true
      const res = await getUserPermissionList({
        storageFileId: this.storageFileId
      })
      if (res.code === 200) {
        this.authLoading = false
        this.memebersInfoList = res.data.map((item) => {
          return {
            label: item.entityName,
            value: item.entityId,
            purviewType: item.purviewType,
            avatar: item.photo
              ? item.photo
              : 'https://static.nihaocafe.com/image/userProfile/woman.png',
            entityType: item.entityType
          }
        })
      } else {
        this.authLoading = false
        Message({
          type: 'error',
          message: res.message ? res.message : 'Server Error!'
        })
      }
    },
    /**
     * @description: 添加成员的初始化
     * @return {*}
     */
    handleAddMemberInit() {
      this.searchMemberDialogVisible = true
      this.searchMemberList = []
      this.selectAuthUserList = []
    },
    /**
     * @description: 搜索员工列表
     * @return {*}
     */
    async remoteSearchMethod(query) {
      // console.log(query)
      if (query !== '') {
        this.searchLoading = true
        const res = await searchUserByValue({
          employeeName: query,
          schoolId: null,
          status: 1
        })
        if (res.code === 200) {
          this.searchLoading = false
          this.searchMemberList = res.data.map((item) => {
            return {
              type: 1,
              value: item.id,
              label: item.englishName
                ? item.englishName
                : item.firstNamePy && item.lastNamePy
                  ? item.lastNamePy + ' ' + item.firstNamePy
                  : item.chineseName,
              id: item.id,
              avatar: item.photo
                ? item.photo
                : 'https://static.nihaocafe.com/image/userProfile/woman.png'
            }
          })
        } else {
          this.searchLoading = false
          Message({
            type: 'error',
            message: res.message ? res.message : 'Server Error!'
          })
        }
      } else {
        this.searchMemberList = []
      }
    },
    /**
     * @description: 选择uservalue
     * @return {*}
     */
    handleSelectUser(list) {
      // console.log('选择的数组：', list)
      this.filterUserData = list.filter((item) => item.type === 1)
    },
    /**
     * @description: 跳转权限设置页面
     * @return {*}
     */
    async handleJumpToAuthSetting() {
      // console.log(this.selectAuthUserList)
      // this.memebersInfoList.push(...this.selectAuthUserList)
      this.searchMemberDialogVisible = false
      const sendData = this.selectAuthUserList.map((item) => {
        return {
          storageFileId: this.storageFileId,
          purviewType: this.defaultAuthType || 1,
          entityId: item.value,
          entityType:
            item.type === 4 || item.type === 1 ? 1 : item.type === 5 ? 3 : 2
        }
      })
      const res = await updatePermissionUserList(sendData)
      if (res.code === 200) {
        console.log(res.data, '++++++++++++++++++')
        this.getAuthUserDataList()
      } else {
        Message({
          type: 'error',
          message: res.message ? res.message : 'Server Error!'
        })
      }
    },
    /**
     * @description: 关闭选择的dialog
     * @return {*}
     */
    handleClosedselectDialog() {
      this.searchMemberDialogVisible = false
      this.selectAuthUserList = []
      this.searchMemberList = []
      this.$store.commit('selectUser', [])
      this.defaultAuthType = 1
    },
    /**
     * @description: 设置单个用户权限
     * @return {*}
     */
    async handleSettingPermisson(e, item) {
      console.log(e, item)
      const sendData = {
        storageFileId: this.storageFileId,
        purviewType: e.authType,
        entityId: item.value,
        entityType: item.entityType
      }
      const res = await updataFileAndFolderAuth(sendData)
      if (res.code === 200) {
        console.log(res.data)
        Message({
          type: 'success',
          message: '操作成功'
        })
        this.getAuthUserDataList()
      } else {
        Message({
          type: 'error',
          message: res.message ? res.message : 'Server Error!'
        })
      }
    },
    /**
     * @description: 删除列表项
     * @param {*} item
     * @return {*}
     */
    async handleDeleteAuthMember(item) {
      console.log(item)

      MessageBox.confirm(
        `删除后，${item.label}将无法查看此内容所做的更改。`,
        '提示',
        {
          confirmButtonText: 'SURE',
          cancelButtonText: 'CANCEL'
        }
      )
        .then(async () => {
          const sendData = {
            storageFileId: this.storageFileId,
            entityId: item.value,
            entityType: item.entityType
          }
          const res = await deleteUserPermission(sendData)
          if (res.code === 200) {
            console.log(res.data)
            Message({
              type: 'success',
              message: '删除成功'
            })
            this.getAuthUserDataList()
          } else {
            Message({
              type: 'error',
              message: res.message ? res.message : 'Server Error!'
            })
          }
        })
        .catch(() => {
          Message({
            type: 'info',
            message: 'Cancel Delete!'
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/color.scss';
.auth-permission-container {
  .permission-title,
  .add-member-btn {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    box-sizing: border-box;
    border: 1px solid $babyGreyBg;
    border-radius: 4px;
    padding: 8px 16px;
    color: $lowFontColor;
    font-size: 14px;
    i {
      margin-right: 10px;
      font-size: 20px;
    }
  }
  .add-member-btn {
    border: none;
    color: $mainBlue;
    padding: 8px 0px;
    i {
      font-size: 22px;
      border: 1px solid $mainBlue;
      border-radius: 50%;
      background: $mainBlue;
      color: $white;
    }
    span {
      cursor: pointer;
    }
  }
  .members-data-list {
    .total-person {
      margin-bottom: 8px;
    }
    .member-list-content {
      .member-list-item {
        line-height: 48px;
        display: flex;
        justify-content: space-between;
        .person-info {
          display: flex;
          align-items: center;
          img {
            width: 36px;
            height: 36px;
            border-radius: 50%;
            margin-right: 10px;
            object-fit: cover;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
@import '../styles/color.scss';
.searchMembersDialog {
  min-width: 600px;
  .dialog-content {
    overflow: auto;
    display: flex;
    justify-content: space-between;
    .search-by-name,
    .search-by-other {
      flex: 1;
    }
    .search-by-name {
      padding: 8px 16px 0 0;
      border-right: 1px solid $greyBg;
      position: relative;
      .el-select {
        width: 100%;
      }
    }
    .search-by-other {
      padding: 8px 0 0 16px;
    }
  }
  .select-default-auth {
    margin-top: 20px;
    .el-radio-group {
      display: flex;
    }
  }
}
.serachByKeywordDropdown {
  .el-select-dropdown__list {
    .el-select-dropdown__item {
      // line-height: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      img {
        width: 36px;
        height: 36px;
        object-fit: cover;
        border-radius: 50%;
        margin-right: 20px;
      }
    }
  }
}
.schoolSelect {
}
</style>
