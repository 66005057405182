<template>
  <div class="file-select-box-container">
    <div class="entity-move-wrapper">
      <div class="first-level-area">
        <div class="first-level-title-header">
          <div class="left-title">ALL</div>
          <div class="right-btn">
            <span
              class="el-icon-folder-add"
              @click="handleOpenAddFolder(0)"
            ></span>
          </div>
        </div>
        <div
          class="first-level-content"
          v-loading="firstLevelLoading"
          element-loading-spinner="el-icon-loading"
        >
          <div
            class="entity-item"
            v-for="(item, index) in firstLevelDataList"
            :class="{ activeStyle: item.id === selectFolder.id }"
            :key="index + 'entity'"
            @click="handleChooseFolder(item)"
            @mouseenter="(showDelete = true), (firstHoverIndex = index)"
            @mouseleave="(showDelete = true), (firstHoverIndex = null)"
          >
            <div class="name">
              <i class="el-icon-folder"></i><span>{{ item.folderName }}</span>
            </div>
            <div
              class="btn-area"
              v-show="showDelete && firstHoverIndex === index"
            >
              <span
                class="el-icon-delete"
                @click.stop="handleDelete(item.id, 0)"
              ></span>
            </div>
          </div>
        </div>
      </div>
      <div
        class="other-level-area"
        v-loading="uploadLoading || otherLevelLoading"
        element-loading-spinner="el-icon-loading"
      >
        <div class="other-level-header">
          <div class="left-area">
            <div class="breadcrumb-area">
              <div class="breadcrumb-item" @click="handleClickBread">
                {{ breadcrumbList.length ? breadcrumbList[0].folderName : "" }}
                <span v-show="breadcrumbList.length > 1">/</span>
              </div>
              <el-popover
                placement="bottom"
                trigger="click"
                popper-class="overflowBreadcrumb"
                ref="overflowBreadcrumbDom"
                v-if="overflowBreadcrumbList.length"
              >
                <div class="overflow-area">
                  <div
                    class="breadcrumb-item"
                    v-for="(item, index) in overflowBreadcrumbList"
                    :key="index"
                    @click="handleClickOverflowBc(item)"
                  >
                    <span class="label">{{ item.folderName }}</span>
                  </div>
                </div>
                <span slot="reference" class="el-icon-more"></span>
              </el-popover>
              <div class="breadcrumb-item" v-if="breadcrumbList.length > 1">
                <span v-show="breadcrumbList.length > 2">/</span>
                {{
                  breadcrumbList.length
                    ? breadcrumbList[breadcrumbList.length - 1].folderName
                    : ""
                }}
              </div>
            </div>
          </div>
          <div class="right-btns" v-show="breadcrumbList.length">
            <span
              class="el-icon-folder-add"
              @click="handleOpenAddFolder(1)"
            ></span>
            <!-- <span class="el-icon-upload"></span> -->
            <el-upload
              :action="''"
              multiple
              ref="uploadRefs"
              :show-file-list="false"
              :http-request="handleUploadFile"
            >
              <span class="el-icon-upload"></span>
            </el-upload>
          </div>
        </div>

        <div class="other-level-content">
          <div
            class="entity-item"
            v-for="(item, index) in otherLevelDataList"
            :key="index + 'entity'"
            @click="handleChooseFolder(item)"
            @mouseenter="(showDelete = true), (hoverIndex = index)"
            @mouseleave="(showDelete = true), (hoverIndex = null)"
          >
            <div class="name">
              <el-checkbox
                v-model="item.isChecked"
                @click.native.stop
                @change="handleChangeSelect(item)"
              ></el-checkbox>
              <i
                :class="
                  item.entityType === 2 ? 'el-icon-document' : 'el-icon-folder'
                "
              ></i
              ><span>{{ item.folderName }}</span>
            </div>

            <div class="btn-area" v-if="showDelete && hoverIndex === index">
              <span
                v-if="item.entityType === 2"
                class="el-icon-view view"
                @click.stop="handlePreviewFile(item)"
              ></span>
              <span
                class="el-icon-delete"
                @click.stop="handleDelete(item.id, 1)"
              ></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="entity-move-btn">
      <span @click="handleCloseMoveDialog">Cancel</span>
      <span @click="handleSaveBind" :class="{ notAllow: false }">Save</span>
    </div>

    <!-- 图片预览 -->
    <div class="img-preview">
      <el-image-viewer
        v-if="imgViewerVisible"
        :on-close="closeImgViewer"
        :url-list="imgList"
        @click="closeImgViewer"
        @mousemove.native="closeImg"
      />
    </div>
  </div>
</template>

<script>
import { Message, MessageBox } from 'element-ui'

import {
  getFolderAndFileList,
  addNewFolder,
  getOssStsToken,
  addNewFile,
  deleteFolderAndFile,
  downloadFileOrFolder,
  getBreadcrumbById,
  fileNameIsExists
} from '../api/index'
const OSS = require('ali-oss')

export default {
  props: {
    isMultiple: {
      type: Boolean,
      default: true
    },
    fileData: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    'el-image-viewer': () =>
      import('element-ui/packages/image/src/image-viewer')
  },
  watch: {
    fileData: {
      handler(val) {
        this.getFirstLevelDataList()
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      fileAndFolderDataList: [],
      firstLevelDataList: [],
      otherLevelDataList: [],
      breadcrumbList: [],
      firstLevelLoading: false,
      uploadLoading: false,
      otherLevelLoading: false,
      selectFolder: {},
      showDelete: false,
      hoverIndex: null,
      firstHoverIndex: null,

      imgViewerVisible: false,
      imgList: [],
      fileDataArr: []
    }
  },
  computed: {
    overflowBreadcrumbList() {
      if (this.breadcrumbList.length > 2) {
        return this.breadcrumbList.slice(1, this.breadcrumbList.length - 1)
      } else {
        return []
      }
    }
  },
  async created() {
    this.firstLevelLoading = true
    this.getFirstLevelDataList()
  },
  methods: {
    /**
     * @description: 初始化OSS实例
     * @param {*} file
     * @return {*}
     */
    async getOssUploadUrl(file, fileName, isFolder = false) {
      // 实例化OSS Client。
      // file表示浏览器中需要上传的文件，支持HTML5 file和Blob类型。
      this.client = new OSS({
        region:
          process.env.NODE_ENV === 'prod'
            ? 'oss-rg-china-mainland'
            : 'oss-cn-shanghai',
        accessKeyId: this.ossAuthData.accessKeyId,
        accessKeySecret: this.ossAuthData.accessKeySecret,
        stsToken: this.ossAuthData.securityToken,
        bucket:
          process.env.NODE_ENV === 'prod' ? 'stdisk-prod' : 'clouddisk-qa',
        timeout: 5 * 60 * 1000
      })
      const todayDate =
        new Date().getFullYear() +
        '-' +
        (1 + new Date().getMonth() + '').padStart(2, '0')
      const userData = JSON.parse(localStorage.getItem('User_Data'))
      const userId = userData.id
      this.uploadLoading = true
      if (file.size > 20 * 1024 * 1024) {
        console.log('大于20M文件,采用分片上传！')

        return await this.handleHugeFileUpload(
          file,
          `${todayDate}/${userId}/${fileName}`
        )
      } else {
        try {
          const res = await this.client.put(
            `${todayDate}/${userId}/${fileName}`,
            file
          )
          // console.log('结果:', res)
          if (res) {
            return res
          } else {
            Message({
              type: 'error',
              message: 'Server Error!'
            })
          }
        } catch (error) {
          console.error('error:', error)
        }
      }
    },
    /**
     * @description: 大文件上传
     * @return {*}
     */
    async handleHugeFileUpload(file, fileName) {
      const options = {
        // 获取分片上传进度、断点和返回值。
        progress: (p, cpt, res) => {
          this.uploadPercentage = parseInt(p * 100)
        },
        // 设置并发上传的分片数量。
        parallel: 100,
        // 设置分片大小。默认值为1 MB，最小值为100 KB。
        partSize: 1024 * 1024
        // mime: 'text/plain'
      }
      try {
        // 分片上传。
        const res = await this.client.multipartUpload(fileName, file, {
          ...options
        })
        // console.log(res)
        const resData = {
          name: res.name,
          res: res.res,
          url: res.res.requestUrls[0].split('?')[0]
          // url: res.res.requestUrls[0]
        }
        // console.log(res, resData)
        return resData
      } catch (err) {
        console.log(err)
      }
    },
    /**
     * @description: 文件上传
     * @return {*}
     */
    async handleUploadFile(fileData) {
      // console.log('fileData:', fileData)
      this.isFolder = false
      const response = await fileNameIsExists({ fileName: fileData.file.name })
      // console.log(res)
      if (response.code === 200) {
        // 上传文件
        const res = await getOssStsToken()
        if (res.code === 200) {
          this.ossAuthData = res.data.credentials
          const uploadResult = await this.getOssUploadUrl(
            fileData.file,
            fileData.file.name
          )
          if (uploadResult) {
            Message({
              type: 'success',
              message: '上传成功'
            })
          }
          // 调保存上传文件的接口
          const sendData = {
            entityType: 2,
            fileName: uploadResult.name.split('/').pop(),
            filePath: uploadResult.url,
            parentId: this.breadcrumbList[this.breadcrumbList.length - 1].id
          }
          // console.log(sendData)
          const result = await addNewFile(sendData)
          if (result.code === 200) {
            this.uploadLoading = false

            this.handleChooseFolder(
              this.breadcrumbList[this.breadcrumbList.length - 1]
            )
          } else {
            this.uploadLoading = false
            Message({
              type: 'error',
              message: result.message ? result.message : 'Server Error!'
            })
          }
        } else {
          this.$message({
            type: 'error',
            message: 'token获取失败!'
          })
        }
      } else {
        this.$message({
          type: 'error',
          message: response.message
        })
      }
    },
    /**
     * @description: 删除文件或者文件夹
     * @return {*}
     */
    handleDelete(id, type) {
      MessageBox.confirm(
        'Are you sure you want to delete this file or folder?',
        'Delete',
        {
          confirmButtonText: 'SURE',
          cancelButtonText: 'CANCEL',
          type: 'warning'
        }
      )
        .then(async () => {
          const res = await deleteFolderAndFile({ id })
          if (res.code === 200) {
            Message({
              type: 'success',
              message: res.message
            })
            if (type === 0) {
              this.getFirstLevelDataList()
            } else {
              this.handleChooseFolder(
                this.breadcrumbList[this.breadcrumbList.length - 1],
                false
              )
            }
          } else {
            Message({
              type: 'error',
              message: res.message ? res.message : 'Server Error!'
            })
          }
        })
        .catch(() => {
          Message({
            type: 'info',
            message: 'Cancel Delete!'
          })
        })
    },
    /**
     * @description: 预览文件
     * @param {*} item
     * @return {*}
     */
    async handlePreviewFile(item) {
      // console.log(item)
      // 获取文件的url地址
      const res = await downloadFileOrFolder({
        id: item.id,
        bucketName:
          process.env.NODE_ENV === 'prod' ? 'stdisk-prod' : 'clouddisk-qa'
      })
      if (res.code === 200) {
        const tokenRes = await getOssStsToken()
        if (tokenRes.code === 200) {
          // console.log(res.data)
          let viewUrl = ''
          if (!res.data.includes('static')) {
            this.ossAuthData = tokenRes.data.credentials
            const client = new OSS({
              region:
                process.env.NODE_ENV === 'prod'
                  ? 'oss-rg-china-mainland'
                  : 'oss-cn-shanghai',
              accessKeyId: this.ossAuthData.accessKeyId,
              accessKeySecret: this.ossAuthData.accessKeySecret,
              stsToken: this.ossAuthData.securityToken,
              bucket:
                process.env.NODE_ENV === 'prod'
                  ? 'stdisk-prod'
                  : 'clouddisk-qa',
              timeout: 5 * 60 * 1000
            })
            // 设置URL的有效时长。单位为秒。如果不设置有效时长，则默认为1800。以3600秒，expires填写为3600。
            const urlLength = res.data.split('/').length
            const filePathName = decodeURIComponent(res.data)
              .split('/')
              .splice(3, urlLength - 1)
              .join('/')
            const response = {
              'content-disposition': 'inline'
            }
            viewUrl = client.signatureUrl(filePathName, {
              expires: 3600,
              response
            })
            // console.log(viewUrl)
            const viewUrlLength = viewUrl.split('/').length
            viewUrl =
              `${
                process.env.NODE_ENV === 'prod'
                  ? 'https://yun.nihaocafe.com/'
                  : 'https://cs-qa.nihaocafe.com/'
              }` +
              viewUrl
                .split('/')
                .splice(3, viewUrlLength - 1)
                .join('/')
          } else {
            viewUrl = res.data
          }
          const fileArray = ['docx', 'xlsx', 'pptx', 'doc']
          const picArray = ['png', 'svg', 'jpg', 'jpeg', 'gif']
          const pdfArray = ['pdf', 'mov', 'mp4']
          const fileName = item.folderName
          const fileTypeString = fileName
            .substring(fileName.lastIndexOf('.') + 1)
            .toLowerCase()
          // console.log(fileTypeString, fileName)

          if (fileArray.indexOf(fileTypeString) > -1) {
            const url = encodeURIComponent(viewUrl)
            const officeUrl =
              'https://view.officeapps.live.com/op/view.aspx?src=' + `${url}`
            // 在新窗口打开编码后 的链接
            window.open(officeUrl, '_target')
          } else {
            if (picArray.indexOf(fileTypeString) > -1) {
              // 展示图片 预览，点击消失
              this.imgList = [viewUrl]
              this.imgViewerVisible = true
              this.stopMove()
            } else if (pdfArray.indexOf(fileTypeString) > -1) {
              window.open(viewUrl, '_target')
            } else {
              this.$notify({
                message: '当前不支持该文件的预览功能',
                type: 'warning',
                position: 'bottom-left',
                duration: 3000
              })
            }
          }
        }
      } else {
        Message({
          type: 'error',
          message: res.message ? res.message : 'Server Error'
        })
      }
    },
    closeImgViewer() {
      this.imgViewerVisible = false
      const m = (e) => {
        e.preventDefault()
      }
      document.body.style.overflow = 'auto'
      document.removeEventListener('touchmove', m, true)
      this.move()
    },
    closeImg(e) {
      const that = this
      if (e.target.currentSrc) {
        e.target.addEventListener('click', function () {
          that.imgViewerVisible = false
        })
      }
    },
    // 停止页面滚动
    stopMove() {
      const m = (e) => {
        e.preventDefault()
      }
      document.body.style.overflow = 'hidden'
      document.addEventListener('touchmove', m, false) // 禁止页面滑动
    },

    // 开启页面滚动
    move() {
      const m = (e) => {
        e.preventDefault()
      }
      document.body.style.overflow = 'auto'
      document.removeEventListener('touchmove', m, true)
    },

    /**
     * @description: 第一层数据获取
     * @return {*}
     */
    async getFirstLevelDataList() {
      const resData = await this.getFolderAndFileDataList()
      this.firstLevelLoading = false
      this.firstLevelDataList = resData.map((item) => {
        return {
          // ...item,
          folderName: item.fileName,
          id: item.id,
          entityType: item.entityType,
          entityId: item.entityId,
          parentId: item.parentId
        }
      })
      if (this.fileData && this.fileData.hierarchicalRelationship) {
        // console.log(this.fileData)()
        const res = await getBreadcrumbById({
          id: this.fileData.storageFileId
        })
        // console.log(res)
        if (res.code === 200) {
          this.fileDataArr = res.data
          if (this.fileDataArr && this.fileDataArr.length) {
            this.fileDataArr.forEach((item) => {
              this.firstLevelDataList.forEach((ele) => {
                if (item.id === ele.id) {
                  this.handleChooseFolder(ele)
                  this.fileDataArr = this.fileDataArr.splice(1)
                }
              })
            })
          } else {
            this.fileDataArr = []
          }
        } else {
          this.$message({
            message: res.message ? res.message : 'Server Error',
            type: 'error',
            position: 'bottom-left',
            duration: 3000
          })
        }
      }
    },
    /**
     * @description:获取云盘数据
     * @return {*}
     */
    async getFolderAndFileDataList(
      entityId = null,
      entityType = null,
      parentId = null,
      orderField = 1,
      orderType = true,
      isLayered = true
    ) {
      const sendData = {
        entityId,
        entityType,
        parentId,
        orderField,
        orderType,
        isLayered
      }
      this.tableLoading = true
      const res = await getFolderAndFileList(sendData)
      if (res.code === 200) {
        // console.log(res.data)
        this.tableLoading = false
        return res.data
      } else {
        this.tableLoading = false
        Message({
          type: 'error',
          message: res.message ? res.message : 'Server Error!'
        })
      }
    },
    /**
     * @description: 新增文件夹
     * @return {*}
     */
    handleOpenAddFolder(type) {
      this.$prompt('', 'Add New Folder', {
        confirmButtonText: 'SAVE',
        cancelButtonText: 'CANCEL'
      })
        .then(async ({ value }) => {
          if (!value) {
            this.$message({
              type: 'warning',
              message: '请输入文件夹名称！'
            })
            return
          }
          this.handleSaveAddNewFoler(
            value,
            type === 0
              ? null
              : this.breadcrumbList[this.breadcrumbList.length - 1].id
          )
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'cancel'
          })
        })
    },
    async handleSaveAddNewFoler(fileName, parentId) {
      const sendData = {
        fileName,
        parentId
      }
      const res = await addNewFolder(sendData)
      if (res.code === 200) {
        this.$message({
          type: 'success',
          message: res.message
        })
        if (!parentId) {
          this.getFirstLevelDataList()
        } else {
          this.handleChooseFolder(
            this.breadcrumbList[this.breadcrumbList.length - 1],
            false
          )
        }
      } else {
        Message({
          type: 'error',
          message: res.message ? res.message : 'Server Error!'
        })
      }
    },
    /**
     * @description: 点击选择文件夹
     * @param {*} item
     * @return {*}
     */
    async handleChooseFolder(item, isCDidk = true) {
      // console.log(item)
      if (item.entityType !== 1) return
      this.otherLevelDataList = []
      this.otherLevelLoading = true
      if (!item.parentId) {
        this.breadcrumbList = []
      }
      const resData = await this.getFolderAndFileDataList(
        null,
        item.entityType,
        item.id
      )
      if (isCDidk) {
        this.breadcrumbList.push(item)
      }
      // console.log(this.breadcrumbList, '面包屑+++++++++')
      this.otherLevelDataList = resData.map((item) => {
        return {
          // ...item,
          folderName: item.fileName,
          id: item.id,
          entityType: item.entityType,
          entityId: item.entityId,
          parentId: item.parentId,
          filePath: `ALL / ${this.breadcrumbList
            .map((ele) => ele.folderName)
            .join(' / ')}`
        }
      })
      this.selectFolder = item
      if (this.fileDataArr && this.fileDataArr.length) {
        this.fileDataArr.forEach((item) => {
          this.otherLevelDataList.forEach((ele) => {
            if (item.id === ele.id && item.parentId === ele.parentId) {
              this.handleChooseFolder(ele)
              this.fileDataArr = this.fileDataArr.splice(1)
            }
          })
        })
      }
      this.otherLevelLoading = false
    },
    /**
     * @description: 单选的处理id
     * @return {*}
     */
    handleChangeSelect({ id }) {
      if (!this.isMultiple) {
        this.otherLevelDataList.forEach((item) => {
          item.isChecked = item.id === id
        })
      }
    },
    /**
     * @description: 点击面包屑的文件夹
     * @return {*}
     */
    async handleClickOverflowBc(item) {
      this.otherLevelLoading = true
      this.$refs.overflowBreadcrumbDom.doClose()
      const index = this.breadcrumbList.map((ele) => ele.id).indexOf(item.id)

      this.breadcrumbList = this.breadcrumbList.splice(0, index + 1)
      const resData = await await this.getFolderAndFileDataList(
        null,
        item.entityType,
        item.id
      )
      this.otherLevelLoading = false
      this.otherLevelDataList = resData.map((item) => {
        // console.log(item)
        return {
          // ...item,
          folderName: item.fileName,
          id: item.id,
          entityType: item.entityType,
          entityId: item.entityId,
          parentId: item.parentId,
          isChecked: false,
          filePath: `ALL / ${this.breadcrumbList
            .map((ele) => ele.folderName)
            .join(' / ')}`
        }
      })
    },
    // 点击第一层面包屑
    async handleClickBread() {
      // console.log(this.breadcrumbList)
      const resData = await this.getFolderAndFileDataList(
        null,
        this.breadcrumbList[0].entityType,
        this.breadcrumbList[0].id
      )
      this.otherLevelLoading = false
      this.otherLevelDataList = resData.map((item) => {
        // console.log(item)
        return {
          // ...item,
          folderName: item.fileName,
          id: item.id,
          entityType: item.entityType,
          entityId: item.entityId,
          parentId: item.parentId,
          isChecked: false,
          filePath: `ALL / ${this.breadcrumbList
            .map((ele) => ele.folderName)
            .join(' / ')}`
        }
      })
      if (this.breadcrumbList && this.breadcrumbList.length > 1) {
        this.breadcrumbList = this.breadcrumbList.splice(0, 1)
      }
    },
    /**
     * @description: 批量选中保存
     * @return {*}
     */
    async handleSaveBind() {
      // console.log('触发保存')
      const list = this.otherLevelDataList.filter((item) => item.isChecked)
      if (list.length) {
        this.otherLevelDataList.forEach((item) => {
          item.isChecked = false
        })
        if (parseInt(JSON.parse(localStorage.getItem('Upload_Type'))) === 1) {
          this.$bus.$emit('uploadFileSelectedEvent', list)
        } else {
          this.$bus.$emit('fileSelectedEvent', list)
          this.$bus.$emit('closeSelectDialogEvent')
        }
      } else {
        this.$message({
          type: 'warning',
          message: '请选择绑定文件'
        })
      }
    },
    /**
     * @description: 取消移动
     * @return {*}
     */
    handleCloseMoveDialog() {
      this.selectFolder = {}
      // this.selectFile = {}
      this.otherLevelDataList.forEach((item) => {
        item.isChecked = false
      })
      this.$bus.$emit('closeSelectDialogEvent')
    }
  }
}
</script>

<style lang="scss" scoped>
.file-select-box-container {
  width: 100%;
  height: 100%;
  .entity-move-wrapper {
    display: flex;
    // padding: 20px 16px;
    min-height: 400px;
    border: 1px solid #9e9e9f;
    border-radius: 6px;
    box-sizing: border-box;
    .first-level-area {
      padding: 10px 0px;
      flex: 1;
      height: 100%;
      border-right: 1px solid #cfcecb;
      .first-level-title-header {
        padding: 8px 16px 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left-title {
          color: #333;
          font-size: 14px;
        }
        .right-btn {
          span {
            font-size: 20px;
            color: #9e9e9f;
            cursor: pointer;
            transition: all 0.3s ease-out;
            &:hover {
              color: #2472b3;
            }
          }
        }
      }
      .first-level-content {
        overflow: auto;
        height: 400px;
        .entity-item {
          height: 36px;
          // line-height: 36px;
          .name {
            display: flex;
            align-items: start;
          }
        }
      }
    }
    .other-level-area {
      flex: 2;
      // height: 100%;
      padding: 10px 0px;
      .other-level-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .right-btns {
          padding: 10px 16px 10px 0px;
          display: flex;
          span {
            font-size: 20px;
            color: #9e9e9f;
            cursor: pointer;
            margin-left: 10px;
            transition: all 0.3s ease-out;
            &:hover {
              color: #2472b3;
            }
          }
        }
      }
      .breadcrumb-area::v-deep {
        // height: 20px;
        padding: 10px 0px 10px 16px;
        display: flex;
        flex-wrap: wrap;
        line-height: 20px;
        .breadcrumb-item {
          cursor: pointer;
          word-break: break-word;
          span {
            margin: 0 5px;
          }
        }
        .el-popover__reference {
          margin: 0px 10px;
          transform: translateY(4px);
          &:hover {
            color: #2472b3;
            cursor: pointer;
          }
        }
        div {
          font-size: 14px;
          padding-bottom: 10px;
        }
      }
      .other-level-content {
        height: 100%;
        max-height: 400px;
        overflow: auto;
      }
    }
    .entity-item {
      // height: 36px;
      // line-height: 36px;
      font-size: 12px;
      display: flex;
      align-items: center;
      padding: 0 16px;
      &:hover {
        background: #e0f1ff;
      }
      display: flex;
      align-items: center;
      justify-content: space-between;
      .name {
        display: flex;
        align-items: center;
        .el-input ::v-deep {
          // width: 80%;
          .el-input__inner {
            height: 30px;
            line-height: 30px;
          }
        }
        .input-box {
          width: 60%;
        }
        .icon-box {
          display: flex;
          align-items: center;
          margin-left: 10px;
          span {
            margin-right: 4px;
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
      i {
        color: #2472b3;
        font-size: 16px;
        margin-right: 8px;
      }
      span {
        color: #333;
        cursor: pointer;
        word-break: break-word;
        // max-width: 200px;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // white-space: nowrap;
      }
      .btn-area {
        transition: all 0.3s ease-out;
        span {
          color: #9e9e9f;
          &:hover {
            color: #ed7221;
          }
        }
        .el-icon-edit {
          margin-right: 10px;
          &:hover {
            color: #2472b3;
          }
        }
        .view {
          margin-right: 10px;
          &:hover {
            color: #2472b3;
          }
        }
      }
    }
    .other-level-content {
      .entity-item {
        line-height: 24px;
        margin-bottom: 10px;
        .name {
          // max-width: 400px;
          width: calc(95% - 60px);
          // overflow: hidden;
          // white-space: nowrap;
          // text-overflow: ellipsis;
        }
        .btn-area {
          width: 40px;
        }
        .el-checkbox {
          margin-right: 10px;
        }
      }
    }
    .activeStyle {
      background: #e0f1ff;
    }
  }
  .entity-move-btn {
    display: flex;
    justify-content: flex-end;
    margin: 16px 0 0px;
    span {
      padding: 8px 16px;
      background: #fff;
      border: 1px solid #cfcecb;
      margin-left: 10px;
      font-size: 12px;
      @media (max-width: 768px) {
        font-size: 13px;
      }
      border-radius: 4px;
      cursor: pointer;
      transition: all 0.3s ease-out;
      &:hover {
        opacity: 0.6;
      }
      &:last-child {
        background: #2472b3;
        color: #fff;
      }
    }
    .notAllow {
      cursor: not-allowed;
      opacity: 0.6;
    }
  }
}
</style>
