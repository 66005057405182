import axios from './axios'

// 添加文件夹
export function addNewFolder(data) {
  return axios({
    method: 'post',
    url: 'CrmCloudStorage/addFolder',
    data
  })
}

// 添加文件
export function addNewFile(data) {
  return axios({
    method: 'post',
    url: 'CrmCloudStorage/addFile',
    data
  })
}

// 获取文件和文件夹列表
export function getFolderAndFileList(data) {
  return axios({
    method: 'post',
    url: 'CrmCloudStorage/getEntityByQuery',
    data
  })
}

// 删除文件和文件夹
export function deleteFolderAndFile(params) {
  return axios({
    method: 'delete',
    url: 'CrmCloudStorage/delEntity',
    params
  })
}

// 批量删除文件或者文件夹
export function batchDeleteFolderAndFile(data) {
  return axios({
    method: 'post',
    url: 'CrmCloudStorage/delEntities',
    data
  })
}

// 获取文件和文件夹列表
export function updateFolderAndFileName(data) {
  return axios({
    method: 'post',
    url: 'CrmCloudStorage/updateEntityName',
    data
  })
}

// 查询权限用户列表
export function getUserPermissionList(params) {
  return axios({
    method: 'get',
    url: 'CrmCloudStorage/getUserByFileId',
    params
  })
}

// 查询员工接口
export function searchUserByValue(data) {
  return axios({
    method: 'post',
    url: 'CrmCloudStorage/getUser',
    data
  })
}

// 查询校区
export function getAvailableSchoolList(params) {
  return axios({
    method: 'get',
    url: 'user/getAvailableSchools',
    params
  })
}

// 根据校区查询部门
export function getDepartmentListBySchoolId(params) {
  return axios({
    method: 'get',
    url: 'CrmCloudStorage/getDepartmentsByBranchId',
    params
  })
}

// 查询角色列表
export function geRolesList(params) {
  return axios({
    method: 'get',
    url: 'roleManage/getRoleList',
    params
  })
}

// 批量添加用户
export function updatePermissionUserList(data) {
  return axios({
    method: 'post',
    url: 'CrmCloudStorage/changePurviewSettings',
    data
  })
}
/// /  todolist

// 设置单个权限
export function updataFileAndFolderAuth(data) {
  return axios({
    method: 'post',
    url: 'CrmCloudStorage/changePurviewSetting',
    data
  })
}

// 删除权限用户
export function deleteUserPermission(data) {
  return axios({
    method: 'post',
    url: 'CrmCloudStorage/delPurviewSetting',
    data
  })
}

// 获取阿里云sts通行证
export function getOssStsToken(params) {
  return axios({
    method: 'get',
    url: 'kbController/getAssumeRole',
    params
  })
}

// 移动文件和文件夹
export function moveFolderAndFile(data) {
  return axios({
    method: 'post',
    url: 'CrmCloudStorage/moveEntity',
    data
  })
}

// 批量移动文件和文件夹
export function batchMoveFolderAndFile(data) {
  return axios({
    method: 'post',
    url: 'CrmCloudStorage/moveEntities',
    data
  })
}

// 上传文件夹
export function uploadFolder(data) {
  return axios({
    method: 'post',
    url: 'CrmCloudStorage/addFolderOrFileByFilePath',
    data
  })
}

// 下载文件或者文件夹  原来的老接口
export function downloadFileOrFolder(params) {
  return axios({
    method: 'get',
    url: 'CrmCloudStorage/getFilePath',
    params
  })
}

// 下载文件或者文件夹  新接口
export function downloadFileOrFolderNew(params) {
  return axios({
    method: 'get',
    url: 'CrmCloudStorage/getFilePathByFunc',
    params
  })
}

export function getFilePathById(params) {
  return axios({
    method: 'get',
    url: 'CrmCloudStorage/getFilePathById',
    params
  })
}

// 根据id获取code
export function getCodeById(params) {
  return axios({
    method: 'get',
    url: 'CrmCloudStorage/getCodeById',
    params
  })
}

// 返回临时访问资源链接
export function getFilePathByCode(params) {
  return axios({
    method: 'get',
    url: 'CrmCloudStorage/getFilePathByCode',
    params
  })
}

// 获取面包屑接口
export function getBreadcrumbById(params) {
  return axios({
    method: 'get',
    url: 'CrmCloudStorage/selectFolderLevel',
    params
  })
}

// 文件名是否重复
export function fileNameIsExists(params) {
  return axios({
    method: 'get',
    url: 'CrmCloudStorage/fileNameIsExists',
    params
  })
}

// 搜索获取文件和文件夹列表
export function searchEntity(data) {
  return axios({
    method: 'post',
    url: 'CrmCloudStorage/searchEntity ',
    data
  })
}
