import PreviewItem from './Preview.vue'
const Preview = {}

Preview.install = function (Vue) {
  const PreviewConstructor = Vue.extend(PreviewItem)
  const instance = new PreviewConstructor()
  instance.$mount(document.createElement('div'))
  document.body.appendChild(instance.$el)

  Vue.prototype.$showImg = function (urlList = [], initialIndex = 0, onSwitch = () => {}) {
    // 在这里加上一些配置
    instance.urlList = urlList
    instance.initialIndex = initialIndex
    instance.onSwitch = onSwitch
    instance.show = true
  }
}

export default Preview
