import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    selectUserDataList: [],
    selectTrainingUserList: [],
    departmentId: JSON.parse(sessionStorage.getItem('Department_Id')) || null,
    schoolId: JSON.parse(sessionStorage.getItem('School_Id')) || null,
    hasAuth: JSON.parse(sessionStorage.getItem('User_Auth')),
    token: sessionStorage.getItem('User_Token')
      ? JSON.parse(sessionStorage.getItem('User_Token'))
      : null,
    trainingSchoolId: null,
    userStandardType: null
  },
  getters: {},
  mutations: {
    selectUser(state, payload) {
      state.selectUserDataList = payload
    },
    selectTrainingUser(state, payload) {
      state.selectTrainingUserList = payload
    },
    setDepartmentId(state, payload) {
      state.departmentId = payload
      sessionStorage.setItem('Department_Id', JSON.stringify(payload))
    },

    setSchoolId(state, payload) {
      state.schoolId = payload
      sessionStorage.setItem('School_Id', JSON.stringify(payload))
    },

    changeUserAuth(state, payload) {
      state.hasAuth = payload
      sessionStorage.setItem('User_Auth', JSON.stringify(payload))
    },
    setToken(state, payload) {
      state.token = payload
      sessionStorage.setItem('User_Token', JSON.stringify(payload))
    },
    setTrainingSchoolId(state, payload) {
      state.trainingSchoolId = payload
    },
    setUserStandardType(state, payload) {
      state.userStandardType = payload
    }
  },
  actions: {},
  modules: {}
})
