var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "file-select-box-container" }, [
    _c("div", { staticClass: "entity-move-wrapper" }, [
      _c("div", { staticClass: "first-level-area" }, [
        _c("div", { staticClass: "first-level-title-header" }, [
          _c("div", { staticClass: "left-title" }, [_vm._v("ALL")]),
          _c("div", { staticClass: "right-btn" }, [
            _c("span", {
              staticClass: "el-icon-folder-add",
              on: {
                click: function ($event) {
                  return _vm.handleOpenAddFolder(0)
                },
              },
            }),
          ]),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.firstLevelLoading,
                expression: "firstLevelLoading",
              },
            ],
            staticClass: "first-level-content",
            attrs: { "element-loading-spinner": "el-icon-loading" },
          },
          _vm._l(_vm.firstLevelDataList, function (item, index) {
            return _c(
              "div",
              {
                key: index + "entity",
                staticClass: "entity-item",
                class: { activeStyle: item.id === _vm.selectFolder.id },
                on: {
                  click: function ($event) {
                    return _vm.handleChooseFolder(item)
                  },
                  mouseenter: function ($event) {
                    ;(_vm.showDelete = true), (_vm.firstHoverIndex = index)
                  },
                  mouseleave: function ($event) {
                    ;(_vm.showDelete = true), (_vm.firstHoverIndex = null)
                  },
                },
              },
              [
                _c("div", { staticClass: "name" }, [
                  _c("i", { staticClass: "el-icon-folder" }),
                  _c("span", [_vm._v(_vm._s(item.folderName))]),
                ]),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showDelete && _vm.firstHoverIndex === index,
                        expression: "showDelete && firstHoverIndex === index",
                      },
                    ],
                    staticClass: "btn-area",
                  },
                  [
                    _c("span", {
                      staticClass: "el-icon-delete",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.handleDelete(item.id, 0)
                        },
                      },
                    }),
                  ]
                ),
              ]
            )
          }),
          0
        ),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.uploadLoading || _vm.otherLevelLoading,
              expression: "uploadLoading || otherLevelLoading",
            },
          ],
          staticClass: "other-level-area",
          attrs: { "element-loading-spinner": "el-icon-loading" },
        },
        [
          _c("div", { staticClass: "other-level-header" }, [
            _c("div", { staticClass: "left-area" }, [
              _c(
                "div",
                { staticClass: "breadcrumb-area" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "breadcrumb-item",
                      on: { click: _vm.handleClickBread },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.breadcrumbList.length
                              ? _vm.breadcrumbList[0].folderName
                              : ""
                          ) +
                          " "
                      ),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.breadcrumbList.length > 1,
                              expression: "breadcrumbList.length > 1",
                            },
                          ],
                        },
                        [_vm._v("/")]
                      ),
                    ]
                  ),
                  _vm.overflowBreadcrumbList.length
                    ? _c(
                        "el-popover",
                        {
                          ref: "overflowBreadcrumbDom",
                          attrs: {
                            placement: "bottom",
                            trigger: "click",
                            "popper-class": "overflowBreadcrumb",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "overflow-area" },
                            _vm._l(
                              _vm.overflowBreadcrumbList,
                              function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass: "breadcrumb-item",
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleClickOverflowBc(item)
                                      },
                                    },
                                  },
                                  [
                                    _c("span", { staticClass: "label" }, [
                                      _vm._v(_vm._s(item.folderName)),
                                    ]),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                          _c("span", {
                            staticClass: "el-icon-more",
                            attrs: { slot: "reference" },
                            slot: "reference",
                          }),
                        ]
                      )
                    : _vm._e(),
                  _vm.breadcrumbList.length > 1
                    ? _c("div", { staticClass: "breadcrumb-item" }, [
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.breadcrumbList.length > 2,
                                expression: "breadcrumbList.length > 2",
                              },
                            ],
                          },
                          [_vm._v("/")]
                        ),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.breadcrumbList.length
                                ? _vm.breadcrumbList[
                                    _vm.breadcrumbList.length - 1
                                  ].folderName
                                : ""
                            ) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.breadcrumbList.length,
                    expression: "breadcrumbList.length",
                  },
                ],
                staticClass: "right-btns",
              },
              [
                _c("span", {
                  staticClass: "el-icon-folder-add",
                  on: {
                    click: function ($event) {
                      return _vm.handleOpenAddFolder(1)
                    },
                  },
                }),
                _c(
                  "el-upload",
                  {
                    ref: "uploadRefs",
                    attrs: {
                      action: "",
                      multiple: "",
                      "show-file-list": false,
                      "http-request": _vm.handleUploadFile,
                    },
                  },
                  [_c("span", { staticClass: "el-icon-upload" })]
                ),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "other-level-content" },
            _vm._l(_vm.otherLevelDataList, function (item, index) {
              return _c(
                "div",
                {
                  key: index + "entity",
                  staticClass: "entity-item",
                  on: {
                    click: function ($event) {
                      return _vm.handleChooseFolder(item)
                    },
                    mouseenter: function ($event) {
                      ;(_vm.showDelete = true), (_vm.hoverIndex = index)
                    },
                    mouseleave: function ($event) {
                      ;(_vm.showDelete = true), (_vm.hoverIndex = null)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "name" },
                    [
                      _c("el-checkbox", {
                        on: {
                          change: function ($event) {
                            return _vm.handleChangeSelect(item)
                          },
                        },
                        nativeOn: {
                          click: function ($event) {
                            $event.stopPropagation()
                          },
                        },
                        model: {
                          value: item.isChecked,
                          callback: function ($$v) {
                            _vm.$set(item, "isChecked", $$v)
                          },
                          expression: "item.isChecked",
                        },
                      }),
                      _c("i", {
                        class:
                          item.entityType === 2
                            ? "el-icon-document"
                            : "el-icon-folder",
                      }),
                      _c("span", [_vm._v(_vm._s(item.folderName))]),
                    ],
                    1
                  ),
                  _vm.showDelete && _vm.hoverIndex === index
                    ? _c("div", { staticClass: "btn-area" }, [
                        item.entityType === 2
                          ? _c("span", {
                              staticClass: "el-icon-view view",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.handlePreviewFile(item)
                                },
                              },
                            })
                          : _vm._e(),
                        _c("span", {
                          staticClass: "el-icon-delete",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.handleDelete(item.id, 1)
                            },
                          },
                        }),
                      ])
                    : _vm._e(),
                ]
              )
            }),
            0
          ),
        ]
      ),
    ]),
    _c("div", { staticClass: "entity-move-btn" }, [
      _c("span", { on: { click: _vm.handleCloseMoveDialog } }, [
        _vm._v("Cancel"),
      ]),
      _c(
        "span",
        { class: { notAllow: false }, on: { click: _vm.handleSaveBind } },
        [_vm._v("Save")]
      ),
    ]),
    _c(
      "div",
      { staticClass: "img-preview" },
      [
        _vm.imgViewerVisible
          ? _c("el-image-viewer", {
              attrs: {
                "on-close": _vm.closeImgViewer,
                "url-list": _vm.imgList,
              },
              on: { click: _vm.closeImgViewer },
              nativeOn: {
                mousemove: function ($event) {
                  return _vm.closeImg.apply(null, arguments)
                },
              },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }