var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    { attrs: { name: "viewer-fade" } },
    [
      _vm.show
        ? _c("ElImageViewer", {
            attrs: {
              "z-index": _vm.zIndex,
              "initial-index": _vm.imageIndex,
              urlList: _vm.urlList,
              "on-close": _vm.closeViewer,
              "on-switch": _vm.switchViewer,
              appendToBody: _vm.appendToBody,
              maskClosable: _vm.maskClosable,
            },
            nativeOn: {
              click: function ($event) {
                return _vm.closeViewer.apply(null, arguments)
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }