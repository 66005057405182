var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.tableLoading || _vm.downloadLoading,
          expression: "tableLoading || downloadLoading",
        },
      ],
      staticClass: "cloud-disk-box-container",
      attrs: { "element-loading-text": _vm.loadingText },
    },
    [
      _c("div", { staticClass: "cloud-disk-header" }, [_c("DiskHeader")], 1),
      _c(
        "div",
        { staticClass: "cloud-disk-content" },
        [
          _c("DiskContent", {
            on: {
              downloadLoading: function ($event) {
                _vm.downloadLoading = $event
              },
              tableLoading: function ($event) {
                _vm.tableLoading = $event
              },
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }