<template>
  <div
    class="cloud-disk-container"
    :class="{ selectStyle: isAttachmentSelectedPage }"
  >
    <div class="header-area" v-if="!isAttachmentSelectedPage">
      <div class="search-content">
        <!-- <el-autocomplete
          v-model="searchValue"
          prefix-icon="el-icon-search"
          :fetch-suggestions="querySearch"
          placeholder="Search"
          @select="handleSelect"
          @change="handleToSearch"
        >
        </el-autocomplete> -->
        <el-input
          v-model="searchValue"
          prefix-icon="el-icon-search"
          @change="handleToSearch"
        ></el-input>
      </div>
    </div>
    <CloudDiskBox v-if="!isAttachmentSelectedPage" />
    <FileSelectBox :isMultiple="isMultiple" v-else />
  </div>
</template>

<script>
import CloudDiskBox from './views/CloudDiskBox.vue'
import FileSelectBox from './views/FileSelectBox.vue'
import { getFolderAndFileList } from './api/index'
export default {
  name: 'cloudDisk',
  components: {
    CloudDiskBox,
    FileSelectBox
  },
  props: {
    isAttachmentSelectedPage: {
      type: Boolean,
      default: false
    },
    isMultiple: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      searchValue: '',
      searchHasFocus: false
    }
  },
  methods: {
    // --------搜索相关-------------
    async querySearch(queryString, cb) {
      // console.log(queryString, 22)
      let results = []
      if (queryString) {
        const sendData = {
          fileName: queryString,
          isSearch: true
          // orderField: 1,
          // isLayered: true,
          // orderType: true
        }
        const res = await getFolderAndFileList(sendData)
        console.log(res)
        if (res.code === 200) {
          results =
            res.data && res.data.length
              ? res.data.map((item) => {
                return {
                  value: item.fileName,
                  id: item.id
                }
              })
              : []
        } else {
          this.$message({
            type: 'error',
            message: res.message
          })
        }
      }
      cb(results)
      // return
      // const searchHistoryList = this.searchHistoryList
      // const results = queryString
      //   ? searchHistoryList.filter(this.createFilter(queryString))
      //   : searchHistoryList
      // // 调用 callback 返回建议列表的数据
    },
    handleSelect(item) {
      // return
      // console.log(item, 'item')
      if (item && item.id) {
        this.$router.push({
          path: `/cloudStorage/${item.fileName}`,
          params: {
            folder: item.fileName
          },
          query: {
            id: item.id
          }
        })
      }
    },
    handleToSearch() {
      // return
      this.$router.push({
        name: 'YunSearch',
        params: {
          keyword: this.searchValue
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.cloud-disk-container {
  width: 100%;
  height: 100%;
  background: #f9f9f9;
  position: relative;
  .header-area {
    width: 100%;
    height: 30px;
    background: #f1f1f0;
    .search-content::v-deep {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      z-index: 1000;
      top: 10px;
      .el-input {
        width: 50%;

        .el-input__inner {
          background: #fff;
          border: none;
          border-radius: 4px;
          // box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        }
        .el-input__prefix {
          right: 10px;
          left: inherit;
        }
      }
    }
  }
  @media (max-width: 768px) {
    width: calc(100% - 40px);
    padding: 20px;
    .header-area {
      position: relative;
      background: #f9f9f9;
      .search-content::v-deep {
        display: inline-block;
        top: -35px;
        .el-input {
          width: 100%;
          .el-input__inner {
            padding-right: 40px;
            padding-left: 20px;
          }
        }
      }
    }
  }
}
.selectStyle {
  width: 100%;
  height: 100%;
  padding: 0;
}
</style>
