<template>
  <div class="entity-move-container">
    <div class="entity-move-wrapper">
      <div class="first-level-area">
        <div class="first-level-title">ALL</div>
        <div class="first-level-content" v-loading="firstLevelLoading">
          <div
            class="entyity-item"
            v-for="(item, index) in firstLevelDataList"
            :key="index + 'entity'"
            @click="handleChooseFolder(item)"
          >
            <i class="el-icon-folder"></i><span>{{ item.folderName }}</span>
          </div>
        </div>
      </div>
      <div class="other-level-area">
        <div class="breadcrumb-area">
          <div class="breadcrumb-item">
            {{ breadcrumbList.length ? breadcrumbList[0].folderName : '' }}
            <span v-show="breadcrumbList.length > 1">/</span>
          </div>
          <el-popover
            placement="bottom"
            trigger="click"
            popper-class="overflowBreadcrumb"
            ref="overflowBreadcrumbDom"
            v-if="overflowBreadcrumbList.length"
          >
            <div class="overflow-area">
              <div
                class="breadcrumb-item"
                v-for="(item, index) in overflowBreadcrumbList"
                :key="index"
                @click="handleClickOverflowBc(item)"
              >
                <span class="label">{{ item.folderName }}</span>
              </div>
            </div>
            <span slot="reference" class="el-icon-more"></span>
          </el-popover>
          <div class="breadcrumb-item" v-if="breadcrumbList.length > 1">
            <span v-show="breadcrumbList.length > 2">/</span>
            {{
              breadcrumbList.length
                ? breadcrumbList[breadcrumbList.length - 1].folderName
                : ''
            }}
          </div>
        </div>
        <div class="other-level-cont" v-loading="otherLevelLoading">
          <div
            class="entyity-item"
            v-for="(item, index) in otherLevelDataList"
            :key="index + 'entity'"
            @click="handleChooseFolder(item)"
          >
            <i
              :class="
                item.entityType === 2 ? 'el-icon-document' : 'el-icon-folder'
              "
            ></i
            ><span>{{ item.folderName }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="entity-move-btn">
      <span @click="handleCloseMoveDialog">Cancel</span>
      <span
        @click="handleSaveMove"
        :class="{ notAllow: !breadcrumbList.length }"
        >Save</span
      >
    </div>
  </div>
</template>

<script>
import { Message } from 'element-ui'

import { getFolderAndFileList } from '../api/index'
export default {
  props: {
    isMultiple: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fileAndFolderDataList: [],
      firstLevelDataList: [],
      otherLevelDataList: [],
      breadcrumbList: [],
      firstLevelLoading: false,
      otherLevelLoading: false
    }
  },
  computed: {
    overflowBreadcrumbList() {
      if (this.breadcrumbList.length > 2) {
        return this.breadcrumbList.slice(1, this.breadcrumbList.length - 1)
      } else {
        return []
      }
    }
  },
  async created() {
    this.firstLevelLoading = true
    const resData = await this.getFolderAndFileDataList()
    this.firstLevelDataList = resData.map((item) => {
      // console.log(item)
      return {
        // ...item,
        folderName: item.fileName,
        id: item.id,
        entityType: item.entityType,
        entityId: item.entityId,
        parentId: item.parentId
      }
    })
    this.firstLevelLoading = false
  },
  methods: {
    /** 获取云盘数据
     * @description:
     * @return {*}
     */
    async getFolderAndFileDataList(
      entityId = null,
      entityType = null,
      parentId = null,
      orderField = 1,
      orderType = true,
      isLayered = true
    ) {
      const sendData = {
        entityId,
        entityType,
        parentId,
        orderField,
        orderType,
        isLayered
      }
      this.tableLoading = true
      const res = await getFolderAndFileList(sendData)
      if (res.code === 200) {
        console.log(res.data)
        this.tableLoading = false
        return res.data
      } else {
        this.tableLoading = false
        Message({
          type: 'error',
          message: res.message ? res.message : 'Server Error!'
        })
      }
    },
    /**
     * @description: 点击选择文件夹
     * @param {*} item
     * @return {*}
     */
    async handleChooseFolder(item) {
      // if (item.entityType === 2) return
      this.otherLevelLoading = true
      if (!item.parentId) {
        this.breadcrumbList = []
      }
      console.log(item)
      const resData = await this.getFolderAndFileDataList(
        null,
        item.entityType,
        item.id
      )
      this.otherLevelLoading = false
      this.otherLevelDataList = resData
        .map((item) => {
          // console.log(item)
          return {
            // ...item,
            folderName: item.fileName,
            id: item.id,
            entityType: item.entityType,
            entityId: item.entityId,
            parentId: item.parentId
          }
        })
        .filter((item) => item.entityType === 1)
      this.breadcrumbList.push(item)
    },
    /**
     * @description: 点击面包屑的文件夹
     * @return {*}
     */
    async handleClickOverflowBc(item) {
      this.otherLevelLoading = true
      this.$refs.overflowBreadcrumbDom.doClose()
      console.log(item.id)
      const index = this.breadcrumbList.map((ele) => ele.id).indexOf(item.id)

      this.breadcrumbList = this.breadcrumbList.splice(0, index + 1)
      const resData = await await this.getFolderAndFileDataList(
        null,
        item.entityType,
        item.id
      )
      this.otherLevelLoading = false
      this.otherLevelDataList = resData
        .map((item) => {
          // console.log(item)
          return {
            // ...item,
            folderName: item.fileName,
            id: item.id,
            entityType: item.entityType,
            entityId: item.entityId,
            parentId: item.parentId
          }
        })
        .filter((item) => item.entityType === 1)
    },
    /**
     * @description: 移动保存
     * @return {*}
     */
    async handleSaveMove() {
      if (!this.breadcrumbList.length) return
      this.$emit('moveEntityEvent', this.breadcrumbList)
    },
    /**
     * @description: 取消移动
     * @return {*}
     */
    handleCloseMoveDialog() {
      this.$emit('closeMoveDialogEvent')
    }
  }
}
</script>

<style lang="scss" scoped>
.entity-move-container {
  width: 100%;
  height: 100%;
  .entity-move-wrapper {
    display: flex;
    // padding: 20px 16px;
    min-height: 400px;
    border: 1px solid #9e9e9f;
    border-radius: 6px;
    .first-level-area {
      padding: 10px 0px;
      flex: 1;
      height: 100%;
      border-right: 1px solid #cfcecb;
      .first-level-title {
        color: #333;
        font-size: 14px;
        padding: 10px 0px 10px 16px;
      }
      .first-level-content {
        overflow: auto;
        height: 400px;
      }
    }
    .other-level-area {
      flex: 2;
      height: 100%;
      padding: 10px 0px;
      .breadcrumb-area::v-deep {
        height: 20px;
        padding: 10px 0px 10px 16px;
        display: flex;
        flex-wrap: wrap;
        .breadcrumb-item {
          span {
            margin: 0 5px;
          }
        }
        .el-popover__reference {
          margin: 0px 10px;
          transform: translateY(4px);
          &:hover {
            color: #2472b3;
            cursor: pointer;
          }
        }
        div {
          font-size: 14px;
          padding-bottom: 10px;
        }
      }
    }
    .entyity-item {
      height: 36px;
      line-height: 36px;
      font-size: 14px;
      display: flex;
      align-items: center;
      padding-left: 16px;
      &:hover {
        background: #e0f1ff;
      }
      i {
        color: #2472b3;
        font-size: 18px;
        margin-right: 8px;
      }
      span {
        color: #333;
        cursor: pointer;
      }
    }
  }
  .entity-move-btn {
    display: flex;
    justify-content: flex-end;
    margin: 16px 0 0px;
    span {
      padding: 8px 16px;
      background: #fff;
      border: 1px solid #cfcecb;
      margin-left: 10px;
      font-size: 12px;
      @media (max-width: 768px) {
        font-size: 13px;
      }
      border-radius: 4px;
      cursor: pointer;
      transition: all 0.3s ease-out;
      &:hover {
        opacity: 0.6;
      }
      &:last-child {
        background: #2472b3;
        color: #fff;
      }
    }
    .notAllow {
      cursor: not-allowed;
      opacity: 0.6;
    }
  }
}
</style>
<style lang="scss">
.overflowBreadcrumb {
  padding: 12px 0;
  border-radius: 4px;
  .overflow-area {
    .breadcrumb-item {
      height: 32px;
      line-height: 32px;
      .label {
        padding: 0 12px;
        cursor: pointer;
        font-size: 12px;
        @media (max-width: 768px) {
          font-size: 13px;
        }
        color: #333;
      }
      &:hover {
        background: #e0f1ff;
      }
    }
  }
}
</style>
