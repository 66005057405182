import Axios from 'axios'

// console.log(process.env)
const axios = Axios.create({
  baseURL: `${
    process.env.NODE_ENV === 'prod' || process.env.VUE_APP_CURENV === 'prod'
      ? 'https://crmapi.nihaocafe.com/crm/'
      : 'http://1.117.182.217:8085/crm/'
    // : 'https://crmtestapi.nihaocafe.com/crm/'
  }/`, // /before
  // baseURL: 'https://crmapi.nihaocafe.com/crm/',
  // baseURL: 'http://1.117.182.217:8085/crm/',

  timeout: 1000 * 60,
  headers: {
    'Content-Type': 'application/json'
  }
})
//
// 请求拦截器
axios.interceptors.request.use(
  (config) => {
    // loading = vue.$loading({
    //   lock: true
    // })
    const token = JSON.parse(localStorage.getItem('User_Token'))

    /* 判断是否已登录 */
    if (token) {
      const userId = JSON.parse(localStorage.getItem('User_Data')).id
      config.headers.token = token
      config.headers.userId = userId
      config.headers['Accept-Language'] = 'en'
    } else {
      // 带url跳转登录页
      console.log(window.location)
      window.location.href = `/login?redirect=${window.location.pathname}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// 响应拦截器
axios.interceptors.response.use(
  (res) => {
    return Promise.resolve(res.data)
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default axios
