import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Layout',
    redirect: '/knowledgeBase',
    component: () =>
      import(/* webpackChunkName: "KBLayout" */ '@/views/KBLayout.vue'),
    children: [
      // KnowledgeBaseindex.vue
      {
        path: 'knowledgeBase',
        name: 'KnowledgeBase',
        redirect: '/knowledgeBase/home',

        component: () =>
          import(/* webpackChunkName: "layout" */ '@/views/KBPlatform/Layout'),

        children: [
          {
            path: 'home',
            name: 'Home',
            component: () =>
              import(
                /* webpackChunkName: "home" */ '@/views/KBPlatform/HomePage'
              )
          },
          // 文件夹
          {
            path: 'category/:categoryName',
            name: 'Category',
            component: () =>
              import(
                /* webpackChunkName: "category" */ '@/views/KBPlatform/CategoryPage'
              )
          },
          // 文章
          {
            path: 'article/:articleName',
            name: 'Article',
            component: () =>
              import(
                /* webpackChunkName: "article" */ '@/views/KBPlatform/ArticlePage'
              )
          },
          // 关联页
          {
            path: 'associatedPage/:associatedName',
            name: 'AssociatedPage',
            component: () =>
              import(
                /* webpackChunkName: "artcle" */ '@/views/KBPlatform/AssociatedPage'
              )
          },
          // 考试页
          {
            path: '/trainingExam/:articleName',
            name: 'TrainingExam',
            component: () =>
              import(
                /* webpackChunkName: "TrainingExam" */ '@/views/KBPlatform/TrainingExamPage/TrainingExam.vue'
              )
          }
        ]
      },
      // TrainingProgram
      {
        path: 'trainingProgram',
        name: 'TrainingProgram',
        component: () =>
          import(
            /* webpackChunkName: "TrainingProgram" */ '@/views/KBManagement/TrainingProgram.vue'
          )
      },
      // 培训系统
      {
        path: '/trainingSystem',
        redirect: 'trainingSystem/trainingCourses',
        name: 'TrainingManagement',
        component: () =>
          import(/* webpackChunkName: "Management" */ '@/views/KBManagement'),
        children: [
          {
            path: 'trainingCourses',
            name: 'TrainingCourses',
            redirect: 'trainingCourses/list',
            component: () =>
              import(
                /* webpackChunkName: "TrainingCourses" */ '@/views/KBManagement/TrainingCourses/index.vue'
              ),
            children: [
              {
                path: 'list',
                name: 'TrainingCoursesList',
                component: () =>
                  import(
                    /* webpackChunkName: "TrainingCoursesList" */ '@/views/KBManagement/TrainingCourses/TrainingCoursesList'
                  )
              },
              {
                path: 'addNew',
                name: 'AddNewCourse',
                component: () =>
                  import(
                    /* webpackChunkName: "AddNewCourse" */ '@/views/KBManagement/TrainingCourses/AddNewCourse'
                  )
              },
              {
                path: 'edit',
                name: 'EditCourse',
                component: () =>
                  import(
                    /* webpackChunkName: "EditCourse" */ '@/views/KBManagement/TrainingCourses/EditCourse'
                  )
              }
            ]
          },
          {
            path: 'questionLibrary',
            name: 'QuestionLibrary',
            component: () =>
              import(
                /* webpackChunkName: "QuestionLibrary" */ '@/views/KBManagement/QuestionLibrary'
              )
          },
          {
            path: 'examTemplates',
            name: 'ExamTemplates',
            redirect: 'examTemplates/list',
            component: () =>
              import(
                /* webpackChunkName: "ExamTemplates" */ '@/views/KBManagement/ExamTemplates'
              ),
            children: [
              {
                path: 'list',
                name: 'ExamTemplatesList',
                component: () =>
                  import(
                    /* webpackChunkName: "ExamTemplatesList" */ '@/views/KBManagement/ExamTemplates/ExamTemplatesList'
                  )
              },
              {
                path: 'addNew',
                name: 'AddNewExamTemplate',
                component: () =>
                  import(
                    /* webpackChunkName: "AddNewExamTemplate" */ '@/views/KBManagement/ExamTemplates/AddAndEditExam'
                  )
              },
              {
                path: 'edit',
                name: 'EditExamTemplate',
                component: () =>
                  import(
                    /* webpackChunkName: "EditExamTemplate" */ '@/views/KBManagement/ExamTemplates/AddAndEditExam'
                  )
              }
            ]
          },
          {
            path: 'reportsAndManagement',
            name: 'ReportsAndManagement',
            component: () =>
              import(
                /* webpackChunkName: "ReportsAndManagement" */ '@/views/KBManagement/ReportsAndManagement'
              )
          }
        ]
      },
      // 云盘
      {
        path: '/cloudStorage/:folder',
        name: 'CloudStorage',
        component: () =>
          import(
            /* webpackChunkName: "CloudStorage" */ '@/views/KBCloudStorage'
          )
      },
      // 云盘搜索
      {
        path: '/cloudStorage/search=:keyword',
        name: 'YunSearch',
        component: () =>
          import(
            /* webpackChunkName: "YunSearch" */ '@/views/KBCloudStorage/YunSearch'
          )
      }
    ]
  },
  {
    path: '/print',
    name: 'Print',

    component: () =>
      import(/* webpackChunkName: "article" */ '@/views/KBPrint')
  },
  {
    path: '/printtest',
    name: 'PrintTest',

    component: () =>
      import(/* webpackChunkName: "article" */ '@/views/KBPrint/indextest.vue')
  },
  {
    path: '/add',
    name: 'Add',
    redirect: '/add/category',

    component: () => import(/* webpackChunkName: "add" */ '@/views/KBCreate'),
    children: [
      {
        path: 'category',
        name: 'AddCategory',
        component: () =>
          import(
            /* webpackChunkName: "AddCategory" */ '@/views/KBCreate/AddCategory'
          )
      },
      {
        path: 'article',
        name: 'AddArticle',
        component: () =>
          import(
            /* webpackChunkName: "AddArticle" */ '@/views/KBCreate/AddArticle'
          )
      }
    ]
  },
  {
    path: '/Edit',
    name: 'Edit',
    redirect: '/edit/category',

    component: () => import(/* webpackChunkName: "edit" */ '@/views/KBEdit'),
    children: [
      {
        path: 'category',
        name: 'EditCategory',
        component: () =>
          import(
            /* webpackChunkName: "EditCategory" */ '@/views/KBEdit/EditCategory'
          )
      },
      {
        path: 'article',
        name: 'EditArticle',
        component: () =>
          import(
            /* webpackChunkName: "EditArticle" */ '@/views/KBEdit/EditArticle'
          )
      }
    ]
  },
  {
    path: '/search/keyword=:keyword',
    name: 'Search',

    component: () =>
      import(/* webpackChunkName: "search" */ '@/views/KBSearch')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "Login" */ '@/views/Login')
  },
  {
    path: '/share/:urlName',
    name: 'SharePage',
    component: () =>
      import(/* webpackChunkName: "SharePage" */ '@/views/KBShare')
  },
  {
    path: '/history',
    name: 'HistoryPage',
    component: () =>
      import(/* webpackChunkName: "HistoryPage" */ '@/views/KBHistory')
  },

  {
    path: '/v/:urlName/:id/:sId/:dId',
    name: 'MiddlePage',
    component: () =>
      import(/* webpackChunkName: "SharePage" */ '@/views/KBShare/MiddlePage')
  },
  {
    path: 'ncJump',
    name: 'MiddleKbPage',
    component: () =>
      import(
        /* webpackChunkName: "MiddleKbPage" */ '@/views/KBShare/MiddleKbPage.vue'
      )
  },
  {
    path: 'crmJump',
    name: 'CRMJumpPage',
    component: () =>
      import(
        /* webpackChunkName: "CRMJumpPage" */ '@/views/KBShare/CRMJumpPage'
      )
  },
  {
    path: 'ncJump',
    name: 'NCJumpPage',
    component: () =>
      import(
        /* webpackChunkName: "CRMJumpPage" */ '@/views/KBShare/NCJumpPage.vue'
      )
  },
  {
    path: '/yun/:code/:file',
    name: 'CloudPath',
    component: () =>
      import(
        /* webpackChunkName: "CloudPath" */ '@/views/KBShare/CloudFilePreview.vue'
      )
  },
  {
    path: '/examEdit',
    name: 'ArticleExamUpdate',
    component: () =>
      import(
        /* webpackChunkName: "ArticleExamUpdate" */ '@/views/KBManagement/ArticleExamUpdate'
      )
  },

  {
    path: '*',
    component: () => import(/* webpackChunkName: "all" */ '@/views/404')
  }
]

const router = new VueRouter({
  routes,
  mode: 'history',
  base: process.env.BASE_URL
  // base: '/usr/dev/Knowledge-base/',
})

export default router
