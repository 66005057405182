<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { judgeUserResigned } from '@/utils/tools'

export default {
  created() {
    if (localStorage.getItem('User_Data')) {
      judgeUserResigned()
      this.timer = setInterval(() => {
        judgeUserResigned()
      }, 300000)
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
  }
}
</script>

<style lang="scss">
#app {
  width: 100%;
  height: 100vh;
}
</style>
