var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "cloud-disk-container",
      class: { selectStyle: _vm.isAttachmentSelectedPage },
    },
    [
      !_vm.isAttachmentSelectedPage
        ? _c("div", { staticClass: "header-area" }, [
            _c(
              "div",
              { staticClass: "search-content" },
              [
                _c("el-input", {
                  attrs: { "prefix-icon": "el-icon-search" },
                  on: { change: _vm.handleToSearch },
                  model: {
                    value: _vm.searchValue,
                    callback: function ($$v) {
                      _vm.searchValue = $$v
                    },
                    expression: "searchValue",
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      !_vm.isAttachmentSelectedPage
        ? _c("CloudDiskBox")
        : _c("FileSelectBox", { attrs: { isMultiple: _vm.isMultiple } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }