var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "permission-popover-container" }, [
    _c(
      "div",
      { staticClass: "permission" },
      [
        _c(
          "el-popover",
          {
            ref: "permissionDom",
            attrs: {
              placement: "left",
              trigger: "click",
              "popper-class": "permission-setting",
            },
          },
          [
            _c(
              "div",
              { staticClass: "permission-content" },
              [
                _vm._l(_vm.permissionOptions, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index + "options",
                      staticClass: "permission-item",
                      on: {
                        click: function ($event) {
                          return _vm.handleSavePermissionSetting(item)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "label" }, [
                        _vm._v(_vm._s(item.label)),
                      ]),
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(item.content)),
                      ]),
                    ]
                  )
                }),
                _c(
                  "div",
                  {
                    staticClass: "permission-item delete-member",
                    on: { click: _vm.handleClickAuthMember },
                  },
                  [_vm._v(" 删除 ")]
                ),
              ],
              2
            ),
            _c(
              "div",
              {
                staticClass: "popover-btn",
                class: {
                  multipleEdit: _vm.authType === -1,
                },
                attrs: { slot: "reference" },
                slot: "reference",
              },
              [
                _c("span", { staticClass: "permission-label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.permissionOptions.find(function (item) {
                        return item.type === _vm.authType
                      }).label
                    )
                  ),
                ]),
                _c("i", { staticClass: "el-icon-caret-bottom" }),
              ]
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }