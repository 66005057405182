<template>
  <div class="disk-header-container">
    <div class="shadow-box">
      <el-progress
        :percentage="uploadPercentage"
        v-if="isHugeFileMode && !isFolder"
      ></el-progress>
    </div>
    <div class="title-and-add">
      <div class="disk-titile">
        <span>TEAM FILES</span>
      </div>
      <div class="btn-area" v-if="breadcrumbList.length > 1">
        <div class="btn-commom add-btn">
          <span @click="handleAddNewInit" class="btn-flex"
            ><i class="el-icon-plus"></i><span>ADD</span></span
          >
        </div>
        <div class="btn-commom upload-btn">
          <el-popover
            placement="bottom"
            trigger="click"
            popper-class="operationPopover"
            ref="uploadDom"
          >
            <div
              class="popover-content"
              v-loading.fullscreen.lock="uploadLoading"
            >
              <div class="operation-item">
                <i class="el-icon-document"></i>

                <el-upload
                  :action="''"
                  multiple
                  :http-request="handleUploadFile"
                  :show-file-list="false"
                >
                  <div class="el-upload__text">Upload File</div>
                </el-upload>
              </div>

              <!-- 文件夹上传 -->
              <div class="upload-item">
                <input
                  type="file"
                  webkitdirectory
                  @change="handleUploadFloder"
                  class="originInput"
                  ref="folderUploadInput"
                />
                <div class="add-from-source">
                  <i class="el-icon-folder"></i>
                  <span class="add-title">Upload Folder</span>
                </div>
              </div>
            </div>
            <span class="btn-flex" slot="reference"
              ><i class="el-icon-upload"></i><span>UPLOAD</span></span
            >
          </el-popover>
        </div>
      </div>
      <div class="btn-area only-add" v-else>
        <div class="btn-commom only-add-btn">
          <span @click="handleAddNewInit" class="btn-flex"
            ><i class="el-icon-plus"></i><span>ADD</span></span
          >
        </div>
      </div>
    </div>
    <div class="breadcrumb-area">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item
          v-for="(item, index) in breadcrumbList"
          :key="index + 'breadcrumb'"
          @click.native="handleBreadcrumbJump(item, index)"
          :class="{ lastItem: index === breadcrumbList.length - 1 }"
          >{{ item.label }}</el-breadcrumb-item
        >
        <!-- <el-breadcrumb-item>全员文件夹</el-breadcrumb-item> -->
      </el-breadcrumb>
    </div>
    <!-- 新增文件夹的dialog -->
    <el-dialog
      title="Add New Folder"
      :visible.sync="addNewDialogVisible"
      @close="handleAddNewClosed"
      custom-class="addNewFolderDialog"
    >
      <el-dialog
        title="Permission Management"
        :visible.sync="authDialogVisible"
        append-to-body
        width="24%"
        custom-class="authSettingDialog"
        @close="handleAuthClosed"
      >
        <div class="dialog-content">
          <AuthPermission :storageFileId="addFolderId" />
        </div>
      </el-dialog>
      <div class="dialog-content">
        <el-input
          v-model="folderInputName"
          placeholder="type folder name"
        ></el-input>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addNewDialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="handleCreatNewFolder">Save</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Message } from 'element-ui'
import {
  addNewFolder,
  getOssStsToken,
  addNewFile,
  uploadFolder,
  getBreadcrumbById,
  fileNameIsExists
} from '../api/index'
import AuthPermission from '../components/AuthPermission.vue'

const OSS = require('ali-oss')
export default {
  name: 'diskHeader',
  components: {
    AuthPermission
  },
  data() {
    return {
      addNewDialogVisible: false,
      authDialogVisible: false,
      folderInputName: '',
      breadcrumbList: [
        {
          parentId: null,
          label: 'ALL',
          entityId: null,
          entityType: null
        }
      ],
      ossAuthData: {},
      uploadLoading: false,
      addFolderId: null,
      client: null,
      isHugeFileMode: false,
      uploadPercentage: 0,
      maxFilesLength: 10000000,
      isFolder: false
    }
  },
  created() {
    // 判断是否来自分享链接
    if (this.$route.query.id) {
      const id = Number(this.$route.query.id)
      this.handleShareBreadcrumb(id)
    }
  },
  watch: {
    $route: {
      handler(value) {
        if (!value.query.id) {
          this.breadcrumbList = [
            {
              parentId: null,
              label: 'ALL',
              entityId: null,
              entityType: null
            }
          ]
        } else {
          this.handleShareBreadcrumb(Number(value.query.id))
        }
        this.$bus.$emit('clickBreadcrumbEvents', { id: value.query.id })
      },
      deep: true
    }
  },
  mounted() {},
  beforeDestroy() {
    this.$bus.$off('setBreadcrumbEvent')
  },
  methods: {
    /**
     * @description: 获取分享链接的面包屑
     * @param {id} 分享链接的id
     * @return {*}
     */
    async handleShareBreadcrumb(id) {
      const res = await getBreadcrumbById({ id })
      if (res.code === 200) {
        // console.log(res.data)
        const curList = res.data.map((item) => {
          return {
            id: item.id,
            entityType: null,
            label: item.fileName,
            parentId: item.parentId
          }
        })
        this.breadcrumbList = [
          {
            id: null,
            entityType: null,
            label: 'ALL',
            parentId: null
          },
          ...curList
        ]
      } else {
        this.$message({
          type: 'error',
          message: res.message || 'server error'
        })
      }
    },
    /**
     * @description: 新增dialog关闭
     * @return {*}
     */
    handleAddNewClosed() {
      this.folderInputName = ''
      // this.addNewDialogVisible = false
    },
    /**
     * @description: 权限dialog关闭
     * @return {*}
     */
    handleAuthClosed() {
      this.folderInputName = ''
      this.addNewDialogVisible = false
    },
    /**
     * @description: 创建新文件的数据初始化
     * @return {*}
     */
    handleAddNewInit() {
      this.addNewDialogVisible = true
    },
    /**
     * @description: 跳转到权限的dialog
     * @return {*}
     */
    async handleCreatNewFolder() {
      if (
        !this.rolePerimission('cloudDisk:addFirstLayerFolder') &&
        this.breadcrumbList.length === 1
      ) {
        Message.warning({
          message: '请向主管申请创建一级文件夹！'
        })
        return
      }
      if (!this.folderInputName) {
        Message.warning({
          message: '请输入文件夹名称！'
          // duration: 0
        })
        return
      }
      const parentId = this.breadcrumbList[this.breadcrumbList.length - 1].id
      // console.log(this.breadcrumbList)
      const sendData = {
        fileName: this.folderInputName,
        parentId
      }
      // console.log(sendData)

      const res = await addNewFolder(sendData)
      this.$bus.$emit('updataCurrentFolderDataEvent')
      if (res.code === 200) {
        // console.log(res)
        this.authDialogVisible = true
        this.addFolderId = res.data
      } else {
        Message({
          type: 'error',
          message: res.message ? res.message : 'Server Error'
        })
      }
    },
    /**
     * @description: 权限判断
     * @param {*} code
     * @return {*}
     */
    rolePerimission(code) {
      const permissonCodeList =
        JSON.parse(localStorage.getItem('User_Data')).permissionCodeList || []
      return permissonCodeList.indexOf(code) > -1
    },
    /**
     * @description: 面包屑跳转
     * @return {*}
     */
    handleBreadcrumbJump(item, index) {
      // console.log(item, index, this.breadcrumbList.length)
      if (index + 1 === this.breadcrumbList.length) return
      if (item.id) {
        this.$router.push({
          params: {
            folder: item.label
          },
          query: {
            id: item.id
          }
        })
      } else {
        this.$router.push({
          params: {
            folder: item.label
          }
        })
      }
    },
    /**
     * @description: 初始化OSS实例
     * @param {*} file
     * @return {*}
     */
    async getOssUploadUrl(file, fileName, isFolder = false) {
      // 实例化OSS Client。
      // file表示浏览器中需要上传的文件，支持HTML5 file和Blob类型。
      this.client = new OSS({
        region:
          process.env.NODE_ENV === 'prod'
            ? 'oss-rg-china-mainland'
            : 'oss-cn-shanghai',
        // region: 'oss-rg-china-mainland',
        accessKeyId: this.ossAuthData.accessKeyId,
        accessKeySecret: this.ossAuthData.accessKeySecret,
        stsToken: this.ossAuthData.securityToken,
        bucket:
          process.env.NODE_ENV === 'prod' ? 'stdisk-prod' : 'clouddisk-qa',
        // bucket: 'stdisk-prod',
        timeout: 5 * 60 * 1000
      })
      // console.log(client)
      // console.log(file)
      const todayDate =
        new Date().getFullYear() +
        '-' +
        (1 + new Date().getMonth() + '').padStart(2, '0')
      const userData = JSON.parse(localStorage.getItem('User_Data'))
      const userId = userData.id
      this.uploadLoading = true
      if (file.size > 20 * 1024 * 1024) {
        console.log('大于20M文件,采用分片上传！')
        if (!this.isFolder) {
          this.isHugeFileMode = true
          this.uploadLoading = false
        }

        return await this.handleHugeFileUpload(
          file,
          `${todayDate}/${userId}/${fileName}`
        )
      } else {
        try {
          const res = await this.client.put(
            `${todayDate}/${userId}/${fileName}`,
            file
          )
          // console.log('结果:', res)
          if (res) {
            return res
          } else {
            Message({
              type: 'error',
              message: 'Server Error!'
            })
          }
        } catch (error) {
          console.error('error:', error)
        }
      }
    },
    /**
     * @description: 大文件上传
     * @return {*}
     */
    async handleHugeFileUpload(file, fileName) {
      const options = {
        // 获取分片上传进度、断点和返回值。
        progress: (p, cpt, res) => {
          this.uploadPercentage = parseInt(p * 100)
        },
        // 设置并发上传的分片数量。
        parallel: 100,
        // 设置分片大小。默认值为1 MB，最小值为100 KB。
        partSize: 1024 * 1024
        // mime: 'text/plain'
      }
      try {
        // 分片上传。
        const res = await this.client.multipartUpload(fileName, file, {
          ...options
        })
        // console.log(res)
        const resData = {
          name: res.name,
          res: res.res,
          url: res.res.requestUrls[0].split('?')[0]
          // url: res.res.requestUrls[0]
        }
        // console.log(res, resData)
        return resData
      } catch (err) {
        console.log(err)
      }
    },
    /**
     * @description: 文件上传
     * @return {*}
     */
    async handleUploadFile(fileData) {
      // console.log(fileData, 'fileData')
      this.isFolder = false
      const todayDate =
        new Date().getFullYear() +
        '-' +
        (1 + new Date().getMonth() + '').padStart(2, '0')
      const userData = JSON.parse(localStorage.getItem('User_Data'))
      const userId = userData.id
      const response = await fileNameIsExists({
        fileName: `${todayDate}/${userId}/${fileData.file.name}`
      })
      if (response.code === 200) {
        // 上传文件
        // console.log(fileData, 'fileData')
        const res = await getOssStsToken()
        if (res.code === 200) {
          // console.log(res)
          this.ossAuthData = res.data.credentials
          // console.log(this.ossAuthData)
          // console.log(response.data.split('/')[response.data.split('/').length - 1])
          const uploadResult = await this.getOssUploadUrl(
            fileData.file,
            response.data
              ? response.data.split('/')[response.data.split('/').length - 1]
              : fileData.file.name
          )
          if (uploadResult) {
            Message({
              type: 'success',
              message: '上传成功'
            })
          }
          // console.log(uploadResult, 'uploadResult')
          // 调保存上传文件的接口
          let url = ''
          if (response.data) {
            url = uploadResult.url.replace(uploadResult.name, response.data)
          }
          const sendData = {
            entityType: 2,
            fileName: uploadResult.name.split('/').pop(),
            filePath: url || uploadResult.url,
            parentId: this.breadcrumbList[this.breadcrumbList.length - 1].id
          }
          const result = await addNewFile(sendData)
          if (result.code === 200) {
            if (fileData.file.size > 20 * 1024 * 1024) {
              this.isHugeFileMode = false
              this.uploadPercentage = 0
            } else {
              this.uploadLoading = false
            }
            this.$refs.uploadDom.doClose()
            this.$bus.$emit('updataCurrentFolderDataEvent')
          } else {
            if (fileData.file.size > 20 * 1024 * 1024) {
              this.isHugeFileMode = false
              this.uploadPercentage = 0
            } else {
              this.uploadLoading = false
            }
            Message({
              type: 'error',
              message: result.message ? result.message : 'Server Error!'
            })
          }
        } else {
          this.$message({
            type: 'error',
            message: 'token获取失败!'
          })
        }
      } else {
        this.$message({
          type: 'error',
          message: response.message
        })
      }
    },
    /**
     * @description: 文件夹上传
     * @param {*} e
     * @return {*}
     */
    async handleUploadFloder(e) {
      // console.log(e.target.files, e.target.files[0].webkitRelativePath)
      // console.log(e.target.files.length, e)
      if (e.target.files.length > this.maxFilesLength) {
        this.$message({
          type: 'warning',
          message: `当前最多可上传文件数为${this.maxFilesLength}！`
        })
        this.$refs.folderUploadInput.value = ''
        this.$refs.uploadDom.doClose()
        return
      }
      this.isFolder = true
      const todayDate =
        new Date().getFullYear() +
        '-' +
        (1 + new Date().getMonth() + '').padStart(2, '0')
      const userData = JSON.parse(localStorage.getItem('User_Data'))
      const userId = userData.id
      const folderName = e.target.files[0].webkitRelativePath.split('/')[0]
      // console.log(folderName)
      const response = await fileNameIsExists({
        fileName: `${todayDate}/${userId}/${folderName}`
      })
      if (response.code === 200) {
        const res = await getOssStsToken()
        if (res.code === 200) {
          this.ossAuthData = res.data.credentials
          let allPromiseList = []
          let fileData = {}
          for (let i = 0; i < e.target.files.length; i++) {
            const newPromisee = new Promise((resolve) => {
              fileData = e.target.files[i]
              let fileName = ''
              if (response.data) {
                fileName = fileData.webkitRelativePath.replace(
                  folderName,
                  response.data.split('/')[response.data.split('/').length - 1]
                )
              }
              // console.log(fileName, 'fileName')
              resolve(
                this.getOssUploadUrl(
                  fileData,
                  fileName || fileData.webkitRelativePath,
                  this.isFolder
                )
              )
            })
            allPromiseList.push(newPromisee)
          }
          // console.log(response.data, fileData.webkitRelativePath.split('/')[0])
          allPromiseList = allPromiseList.map((p) => p.catch((e) => e))
          const nameList = await Promise.all(allPromiseList)
          // nameList.forEach((item) =>
          //   item.url.replace(fileData.webkitRelativePath, response.data)
          // )
          // console.log(nameList, fileData.webkitRelativePath, response.data)
          if (nameList.length) {
            const sendData = {
              filePaths: nameList.map((item) => item.url),
              fixedRoute:
                nameList[0].url.split('/').splice(0, 5).join('/') + '/',
              parentId: this.breadcrumbList[this.breadcrumbList.length - 1].id
            }
            const res = await uploadFolder(sendData)
            // console.log(res)
            if (res.code === 200) {
              this.uploadLoading = false
              const successLength = nameList.filter((item) => item.url).length
              Message({
                type: 'success',
                message: `上传完成！成功：${successLength} , 失败：${
                  nameList.length - successLength
                }`
              })
              this.$refs.uploadDom.doClose()
              this.$bus.$emit('updataCurrentFolderDataEvent')
            } else {
              this.uploadLoading = false
              Message({
                type: 'error',
                message: res.message ? res.message : 'Server Error!'
              })
            }

            // console.log(nameList)
          }
        } else {
          this.uploadLoading = false
          Message({
            type: 'error',
            message: res.message ? res.message : 'Server Error!'
          })
        }
      } else {
        this.$message.error(response.message)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/color.scss";
.disk-header-container::v-deep {
  position: relative;

  .shadow-box {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .el-progress {
    width: 50%;
    .el-progress-bar {
      .el-progress-bar__inner {
        background: #2472b3;
      }
    }
    .el-progress__text {
      color: #333 !important;
      margin-left: 15px;
      font-size: 13px !important;
    }
  }
  .title-and-add {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    .disk-titile {
      color: $mainFontColor;
      font-size: 16px;
      white-space: nowrap;
    }

    .btn-commom {
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px 20px;
      border-radius: 34px;
      cursor: pointer;
      font-size: 12px;
      @media (max-width: 768px) {
        font-size: 13px;
      }
      transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
      &:hover {
        opacity: 0.9;
        transform: translateY(-1px);
      }
      i {
        margin-right: 5px;
        font-size: 12px;
        @media (max-width: 768px) {
          font-size: 13px;
        }
      }
    }

    .btn-area {
      display: flex;
      .add-btn {
        color: #333;
        background: #f1f1f0;
        &:hover {
          opacity: 0.8;
        }
      }
      .upload-btn {
        color: #fff;
        background: #2472b3;
        margin-left: 10px;
      }
    }

    .only-add {
      transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
      .only-add-btn {
        color: #fff;
        background: $mainBlue;
        margin-right: 0;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
  .breadcrumb-area {
    .el-breadcrumb {
      .el-breadcrumb__inner {
        transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        cursor: pointer;
        color: #cfcecb;
        font-family: "MontserratSemibold";

        &:hover {
          color: $mainBlue;
        }
      }
      .el-breadcrumb__separator {
        margin: 0 4px;
        color: #cfcecb;
        font-family: "MontserratSemibold";
      }
    }
    .lastItem {
      .el-breadcrumb__inner {
        cursor: default;

        &:hover {
          color: #9e9e9f;
        }
      }
    }
  }
  .addNewFolderDialog {
    width: 80%;
    max-width: 400px;
  }
  @media (max-width: 768px) {
    .breadcrumb-area {
      .el-breadcrumb {
        height: auto;
        overflow-x: scroll;
        overflow-y: hidden;
        height: 50px;
        line-height: 50px;
        .el-breadcrumb__item .el-breadcrumb__inner {
          color: #cfcecb;
        }
        .el-breadcrumb__item.lastItem .el-breadcrumb__inner {
          color: #333;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.authSettingDialog {
  min-width: 420px;
  max-width: 550px;
  min-height: 500px;
  .el-dialog__body {
    padding-right: 0 !important;
  }
  .members-data-list {
    max-height: 400px;
    overflow: auto;
    padding-right: 20px;
  }
}
.operationPopover {
  .upload-item {
    position: relative;
    height: 36px;
    line-height: 36px;
    padding: 0 12px;
    .originInput {
      // display: none;
      position: absolute;
      opacity: 0;
      height: 32px;
      width: 100%;
      top: 5px;
      left: -35px;
    }
    cursor: pointer;
    input {
      cursor: pointer;
    }
    i {
      font-size: 16px;
      margin-right: 10px;
    }
    .add-title {
      font-size: 14px;
      cursor: pointer;
    }
  }
}
.el-message-box__wrapper {
  .el-message-box__header {
    span {
      font-size: 14px;
      font-family: "MontserratRegular";
    }
  }
  .el-message-box__input {
    padding-top: 0;
    .el-message-box__errormsg {
      display: none;
    }
  }
  .el-message-box__btns {
    .el-button {
      font-size: 12px;
      @media (max-width: 768px) {
        font-size: 13px;
      }
    }
  }
}
</style>
