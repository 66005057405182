var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "entity-move-container" }, [
    _c("div", { staticClass: "entity-move-wrapper" }, [
      _c("div", { staticClass: "first-level-area" }, [
        _c("div", { staticClass: "first-level-title" }, [_vm._v("ALL")]),
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.firstLevelLoading,
                expression: "firstLevelLoading",
              },
            ],
            staticClass: "first-level-content",
          },
          _vm._l(_vm.firstLevelDataList, function (item, index) {
            return _c(
              "div",
              {
                key: index + "entity",
                staticClass: "entyity-item",
                on: {
                  click: function ($event) {
                    return _vm.handleChooseFolder(item)
                  },
                },
              },
              [
                _c("i", { staticClass: "el-icon-folder" }),
                _c("span", [_vm._v(_vm._s(item.folderName))]),
              ]
            )
          }),
          0
        ),
      ]),
      _c("div", { staticClass: "other-level-area" }, [
        _c(
          "div",
          { staticClass: "breadcrumb-area" },
          [
            _c("div", { staticClass: "breadcrumb-item" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.breadcrumbList.length
                      ? _vm.breadcrumbList[0].folderName
                      : ""
                  ) +
                  " "
              ),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.breadcrumbList.length > 1,
                      expression: "breadcrumbList.length > 1",
                    },
                  ],
                },
                [_vm._v("/")]
              ),
            ]),
            _vm.overflowBreadcrumbList.length
              ? _c(
                  "el-popover",
                  {
                    ref: "overflowBreadcrumbDom",
                    attrs: {
                      placement: "bottom",
                      trigger: "click",
                      "popper-class": "overflowBreadcrumb",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "overflow-area" },
                      _vm._l(
                        _vm.overflowBreadcrumbList,
                        function (item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "breadcrumb-item",
                              on: {
                                click: function ($event) {
                                  return _vm.handleClickOverflowBc(item)
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "label" }, [
                                _vm._v(_vm._s(item.folderName)),
                              ]),
                            ]
                          )
                        }
                      ),
                      0
                    ),
                    _c("span", {
                      staticClass: "el-icon-more",
                      attrs: { slot: "reference" },
                      slot: "reference",
                    }),
                  ]
                )
              : _vm._e(),
            _vm.breadcrumbList.length > 1
              ? _c("div", { staticClass: "breadcrumb-item" }, [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.breadcrumbList.length > 2,
                          expression: "breadcrumbList.length > 2",
                        },
                      ],
                    },
                    [_vm._v("/")]
                  ),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.breadcrumbList.length
                          ? _vm.breadcrumbList[_vm.breadcrumbList.length - 1]
                              .folderName
                          : ""
                      ) +
                      " "
                  ),
                ])
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.otherLevelLoading,
                expression: "otherLevelLoading",
              },
            ],
            staticClass: "other-level-cont",
          },
          _vm._l(_vm.otherLevelDataList, function (item, index) {
            return _c(
              "div",
              {
                key: index + "entity",
                staticClass: "entyity-item",
                on: {
                  click: function ($event) {
                    return _vm.handleChooseFolder(item)
                  },
                },
              },
              [
                _c("i", {
                  class:
                    item.entityType === 2
                      ? "el-icon-document"
                      : "el-icon-folder",
                }),
                _c("span", [_vm._v(_vm._s(item.folderName))]),
              ]
            )
          }),
          0
        ),
      ]),
    ]),
    _c("div", { staticClass: "entity-move-btn" }, [
      _c("span", { on: { click: _vm.handleCloseMoveDialog } }, [
        _vm._v("Cancel"),
      ]),
      _c(
        "span",
        {
          class: { notAllow: !_vm.breadcrumbList.length },
          on: { click: _vm.handleSaveMove },
        },
        [_vm._v("Save")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }