<template>
  <div class="permission-popover-container">
    <div class="permission">
      <el-popover
        placement="left"
        trigger="click"
        popper-class="permission-setting"
        ref="permissionDom"
      >
        <div class="permission-content">
          <div
            class="permission-item"
            v-for="(item, index) in permissionOptions"
            :key="index + 'options'"
            @click="handleSavePermissionSetting(item)"
          >
            <div class="label">{{ item.label }}</div>
            <div class="content">{{ item.content }}</div>
          </div>
          <div
            class="permission-item delete-member"
            @click="handleClickAuthMember"
          >
            删除
          </div>
        </div>

        <div
          class="popover-btn"
          slot="reference"
          :class="{
            multipleEdit: authType === -1
          }"
        >
          <span class="permission-label">{{
            permissionOptions.find((item) => item.type === authType).label
          }}</span>
          <i class="el-icon-caret-bottom"></i>
        </div>
      </el-popover>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PermissionBtn',
  props: {
    authType: {
      type: Number,
      require: false
    }
  },
  data () {
    return {
      permissionOptions: [
        {
          type: 4,
          label: '可管理',
          content: '可上传下载/查看/编辑/删除'
        },
        {
          type: 3,
          label: '可编辑',
          content: '可上传下载/查看/编辑'
        },
        {
          type: 2,
          label: '可查看/下载',
          content: '可下载/查看 '
        },
        {
          type: 1,
          label: '仅可查看',
          content: '仅可在线预览'
        }
      ]
    }
  },
  methods: {
    /**
     * @description: 权限设置
     * @param {*} item
     * @return {*}
     */
    async handleSavePermissionSetting (item) {
      console.log(item)
      this.$emit('changeThePermissionLabel', {
        authType: item.type
      })
      this.$refs.permissionDom.doClose()
    },
    /**
     * @description: 删除列表项
     * @return {*}
     */
    handleClickAuthMember () {
      this.$emit('deleteAuthMemberEvent')
    }
  }
}
</script>

<style lang="scss" scoped>
.permission-popover-container {
  .popover-btn {
    cursor: pointer;
    .permission-label {
      font-size: 13px;
    }
    i {
      font-size: 14px;
      margin-left: 5px;
    }
  }
  .multipleEdit {
    i {
      display: none;
    }
    .permission-label {
      padding: 8px 20px;
      background: #2372b3;
      color: #fff;
      border-radius: 20px;
      &:hover {
        color: #fff !important;
        opacity: 0.6;
      }
    }
  }
  .permission-label:hover {
    color: #2372b3;
  }
}
</style>
<style lang="scss">
@import '../styles/color.scss';
.permission-setting {
  border-radius: 5px;
  width: 200px;
  padding: 10px 0;
  color: $mainFontColor;

  .popper__arrow {
    display: none;
  }
  .permission-content {
    .permission-item {
      line-height: 54px;
      height: 54px;
      //   margin: 5px 0;
      padding: 0 16px;
      cursor: pointer;
      .label {
        font-size: 14px;
        line-height: 25px;
      }
      .content {
        font-size: 12px;
        @media (max-width: 768px) {
          font-size: 13px;
        }
        color: #cfcecb;
        line-height: 20px;
      }
    }
    .delete-member {
      border-top: 1px solid $greyBg;
      color: $mainRed;
      height: 42px;
      line-height: 42px;
    }
    .permission-item:hover {
      background: #ecf5ff;
    }
  }
}
</style>
