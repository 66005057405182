import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// global css
import '@/style/commom.scss'
// element ui
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import Preview from '../src/components/TiptapEditor/TiptapComponents/Preview/Preview.js'
import 'remixicon/fonts/remixicon.css'
import cloudStorage from '../packages/cloud-disk'

import '@/assets/font-family/Montserrat-Regular-normal'
// console.log(cloudStorage.CloudDisk)
Vue.component('CloudStorage', cloudStorage.CloudDisk)
// eventsBus 事件总线
const eventBus = new Vue({})
Vue.prototype.$bus = eventBus
Vue.use(Preview)
Vue.use(ElementUI)
Vue.config.productionTip = false

// 路由全局守卫
const whiteList = [
  'SharePage',
  'MiddlePage',
  'CRMJumpPage',
  'NCJumpPage',
  'MiddleKbPage',
  'CloudPath'
]
router.beforeEach((to, from, next) => {
  // console.log(to, whiteList.indexOf(to.name) > -1)
  const token = localStorage.getItem('User_Token')
  // console.log(token)
  if (token || to.name === 'Login' || whiteList.indexOf(to.name) > -1) {
    if (JSON.parse(localStorage.getItem('Last_School_Department_Data'))) {
      const { schoolId, departmentId } = JSON.parse(
        localStorage.getItem('Last_School_Department_Data')
      )
      store.commit('setSchoolId', schoolId)
      store.commit('setDepartmentId', departmentId)
    }
    if (to.query.isFromCRM) {
      if (to.query.articleId) {
        next({
          name: 'MiddlePage',
          params: {
            id: to.query.articleId,
            urlName: to.query.urlName,
            sId: to.query.sId,
            dId: to.query.dId
          }
        })
      } else {
        next({
          name: 'Home'
        })
      }
    } else if (to.query.isFromNC) {
      next({
        name: 'MiddleKbPage',
        params: {
          id: to.query.id,
          entityType: to.query.entityType,
          sId: to.query.sId,
          dId: to.query.dId
        }
      })
    } else {
      next()
    }
  } else {
    // crm跳转的判断
    console.log(to, to.query)
    if (to.query.isFromCRM) {
      next({
        name: 'CRMJumpPage',
        params: {
          // token: to.query.token,
          code: to.query.code,
          articleId: to.query.articleId,
          urlName: to.query.urlName,
          sId: to.query.sId,
          dId: to.query.dId
        }
      })
    } else if (to.query.isFromNC) {
      // console.log(666)
      next({
        name: 'NCJumpPage',
        params: {
          code: to.query.code,
          id: to.query.id,
          entityType: to.query.entityType,
          sId: to.query.sId,
          dId: to.query.dId
        }
      })
    } else if (to.query.id) {
      const redirectPath = to.fullPath
      next({
        name: 'Login',
        query: { redirect: redirectPath }
      })
    } else {
      next({
        name: 'Login'
      })
    }
  }
})
const vue = new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')

export default vue
