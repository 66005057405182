<template>
  <div class="select-by-entity-container">
    <div class="nav-area">
      <el-select v-model="searchTypeId" @change="handleSelectSearchType">
        <el-option
          v-for="item in selectNavList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <div class="select-area" v-if="searchTypeId">
      <div class="by-organization" v-if="searchTypeId === 1">
        <div class="back-last-level" v-show="showBackIndex !== 1">
          <span @click="handleBackToLastLevel">返回上一级</span>
        </div>
        <div
          class="select-item"
          v-for="(item, index) in currentOrganizationList"
          :key="index + 'organization'"
        >
          <div class="radio-area">
            <input
              type="radio"
              :name="item.label"
              :id="item.value"
              :checked="item.isChecked"
              @click="handleRadioChange(item)"
              v-if="item.level !== 1"
            />
            <span class="label" :class="{ labelChecked: item.isChecked }">{{
              item.label
            }}</span>
          </div>
          <span
            class="next-floor"
            v-if="item.level !== 3"
            @click="getSubordinateData(item)"
            :class="{ checkedStyle: item.isChecked }"
            >下级</span
          >
        </div>
      </div>
      <div class="by-roles" v-else>
        <div
          class="select-item"
          v-for="(item, index) in currentRoleDataList"
          :key="index + 'organization'"
        >
          <div class="radio-area">
            <input
              type="radio"
              :name="item.label"
              :id="item.value"
              :checked="item.isChecked"
              @click="handleRadioChange(item)"
            />
            <span class="label">{{ item.label }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Message } from 'element-ui'
import {
  searchUserByValue,
  getDepartmentListBySchoolId,
  getAvailableSchoolList,
  geRolesList
} from '../api/index'
export default {
  data() {
    return {
      searchTypeId: 1,
      selectNavList: [
        {
          value: 1,
          label: '按组织架构选择'
        },
        {
          value: 2,
          label: '按角色选择'
        }
      ],
      schoolDataList: [],
      currentOrganizationList: [],
      currentRoleDataList: [],
      selectOrgDataList: [],
      currentSchoolId: null
    }
  },
  created() {
    this.handleSelectSearchType(this.searchTypeId)
  },
  computed: {
    showBackIndex() {
      return this.currentOrganizationList.length
        ? this.currentOrganizationList[0].level
        : false
    }
  },
  methods: {
    /**
     * @description: 选择选择类型
     * @param {*} type
     * @return {*}
     */
    handleSelectSearchType(type) {
      this.searchTypeId = type
      switch (type) {
        case 1:
          this.getUserCampusList()
          break
        case 2:
          this.getRoleDataList()
          break
      }
    },
    /**
     * @description: 获取校区
     * @return {*}
     */
    async getUserCampusList() {
      const res = await getAvailableSchoolList()
      if (res.code === 200) {
        // console.log(res.data)
        this.currentOrganizationList = res.data
          .map((item) => {
            return {
              type: 2,
              value: item.value,
              label:
                item.label === 'Marketing'
                  ? '总部'
                  : item.label.toLocaleUpperCase(),
              level: 1,
              isChecked: false
            }
          })
          .filter((item) => [2, 3, 11].indexOf(item.value) === -1)
      } else {
        Message({
          type: 'error',
          message: res.message ? res.message : 'Server Error!'
        })
      }
    },
    /**
     * @description: 获取角色列表
     * @return {*}
     */
    async getRoleDataList() {
      const res = await geRolesList({
        pages: 1,
        size: 100
      })
      if (res.code === 200) {
        // console.log(res.data)
        this.currentRoleDataList = res.data.map((item) => {
          return {
            value: item.id,
            label: item.name,
            type: 5,
            isChecked: false
          }
        })
        this.currentRoleDataList.forEach((item) => {
          this.$store.state.selectUserDataList.forEach((ele) => {
            if (item.value === ele.value && item.type === ele.type) {
              item.isChecked = true
            }
          })
        })
      } else {
        Message({
          type: 'error',
          message: res.message ? res.message : 'Server Error!'
        })
      }
    },
    /** value
     * @description: 组织 radio勾选
     * @return {*}item
     */
    handleRadioChange(item) {
      console.log(this, this.$store)
      item.isChecked = !item.isChecked
      const storeDataList = this.$store.state.selectUserDataList.map(
        (ele) => ele
      )
      let selectDataList = []

      // console.log(item, item.isChecked)
      if (!item.isChecked) {
        selectDataList = storeDataList.filter(
          (ele) => !(ele.value === item.value && ele.type === item.type)
        )
      } else {
        selectDataList = [...storeDataList, item]
      }
      this.$store.commit('selectUser', selectDataList)
    },
    /**
     * @description: 点击下一级数据获取
     * @return {*}item
     */
    getSubordinateData(item) {
      // console.log(item.value, item.level)
      if (item.isChecked) return
      if (item.level === 1) {
        // 根据校区获取部门列表
        this.getDepartmentListData(item.value)
      } else {
        // 根据部门获取员工列表
        this.getUserByDepartmentId([item.value])
      }
    },
    /**
     * @description: 根据校区获取部门列表
     * @return {*}
     */
    async getDepartmentListData(branchId) {
      console.log(this, this.$store)
      this.currentOrganizationList = []
      this.currentSchoolId = branchId
      const res = await getDepartmentListBySchoolId({
        branchId
      })
      if (res.code === 200) {
        // console.log(res.data)
        this.currentOrganizationList = res.data.map((item) => {
          return {
            type: 3,
            value: item.id,
            label: item.departmentName,
            level: 2,
            isChecked: false
          }
        })
        this.currentOrganizationList.forEach((item) => {
          this.$store.state.selectUserDataList.forEach((ele) => {
            if (item.value === ele.value && item.type === ele.type) {
              item.isChecked = true
            }
          })
        })
      } else {
        Message({
          type: 'error',
          message: res.message ? res.message : 'Server Error!'
        })
      }
    },
    /**
     * @description: 根据部门获取部门成员
     * @return {*}
     */
    async getUserByDepartmentId(departmentIds) {
      this.currentOrganizationList = []
      const res = await searchUserByValue({
        departmentIds,
        status: 1
      })
      if (res.code === 200) {
        // console.log(res.data)
        this.currentOrganizationList = res.data.map((item) => {
          return {
            type: 4,
            value: item.id,
            label: item.englishName
              ? item.englishName
              : item.firstNamePy && item.lastNamePy
                ? item.lastNamePy + ' ' + item.firstNamePy
                : item.chineseName,
            level: 3,
            isChecked: false
          }
        })
        this.currentOrganizationList.forEach((item) => {
          this.$store.state.selectUserDataList.forEach((ele) => {
            if (item.value === ele.value && item.type === ele.type) {
              item.isChecked = true
            }
          })
        })
      } else {
        Message({
          type: 'error',
          message: res.message ? res.message : 'Server Error!'
        })
      }
    },
    /**
     * @description: 返回上一层
     * @return {*}
     */
    handleBackToLastLevel() {
      if (this.showBackIndex === 3) {
        // 根据校区获取部门列表
        this.getDepartmentListData(this.currentSchoolId)
      } else {
        // 根据部门获取员工列表
        this.getUserCampusList()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/color.scss";

.select-by-entity-container {
  .nav-area::v-deep {
    .el-select {
      width: 95%;
    }
  }

  .select-area::v-deep {
    height: 500px;
    overflow: auto;

    .back-last-level {
      margin: 0px 0 10px 16px;
      color: #333;
      cursor: pointer;

      &:hover {
        color: $mainBlue;
      }
    }

    .by-organization,
    .by-roles {
      padding: 10px 0px;
      width: 95%;

      .select-item {
        padding: 12px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:hover {
          background: $babyGreyBg;
        }

        .radio-area {
          display: flex;
          align-items: center;
          color: $mainFontColor;
          padding-left: 16px;

          .label {
            margin-left: 8px;
            // cursor: pointer;
          }

          input {
            cursor: pointer;
          }

          input:checked {
            color: $mainBlue;
          }

          .labelChecked {
            color: $babyFontColor;
          }
        }

        .el-radio {
          color: $mainFontColor;
          padding-left: 16px;
        }

        .next-floor {
          padding-right: 16px;
          color: $babyFontColor;
          font-size: 12px;

          @media (max-width: 768px) {
            font-size: 13px;
          }

          cursor: pointer;
          box-sizing: border-box;
          border-left: 1px solid $babyFontColor;
          padding-left: 5px;

          &:hover {
            color: $mainBlue;
          }
        }

        .checkedStyle {
          cursor: not-allowed;

          // color: $babyGreyBg;
          &:hover {
            color: $babyFontColor;
          }
        }
      }
    }
  }
}
</style>
