<template>
  <transition name="viewer-fade">
    <ElImageViewer
      v-if="show"
      :z-index="zIndex"
      :initial-index="imageIndex"
      :urlList="urlList"
      :on-close="closeViewer"
      @click.native="closeViewer"
      :on-switch="switchViewer"
      :appendToBody="appendToBody"
      :maskClosable="maskClosable"
    />
  </transition>
</template>
<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
export default {
  props: {
    urlList: {
      type: Array,
      default: () => []
    },
    zIndex: {
      type: Number,
      default: 3000
    },
    onSwitch: {
      type: Function,
      default: () => {}
    },
    onClose: {
      type: Function,
      default: () => {}
    },
    initialIndex: {
      type: Number,
      default: 0
    },
    appendToBody: {
      type: Boolean,
      default: true
    },
    maskClosable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      show: false,
      imageIndex: 0
    }
  },
  methods: {
    closeViewer() {
      this.show = false
      this.onClose()
    },
    switchViewer(index) {
      this.onSwitch(index)
    }
  },
  components: {
    ElImageViewer
  }
}
</script>
